import { defineNuxtPlugin } from '#imports'

import { registerBrazeServiceWorker } from '@backmarket/nuxt-module-tracking/registerBrazeServiceWorker'

/**
 * Registers Braze service worker on client side (service workers run on the browser).
 * This service worker is used to "watch" for incoming web notifications
 * and display them if the customer allowed them through the browser native prompt.
 */
export default defineNuxtPlugin(() => {
  registerBrazeServiceWorker()
})
