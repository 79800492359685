import { useRuntimeConfig } from '#imports'

import type { LoggerConfiguration } from '@backmarket/front-logger'

/**
 * Return all the attributes that must be added to all logs no matter what.
 * They are then merged with the ones passed to the `createLogger` function.
 */
export function getDefaultAttributes() {
  const config = useRuntimeConfig().public.logger

  return {
    environment: config.environment,
    continent: config.continent,
    source: process.server ? 'node' : 'browser',
    service: {
      name: config.service,
      version: config.buildImage.version,
      build: {
        timestamp: config.buildImage.timestamp,
        sha: config.buildImage.sha,
      },
    },
  } satisfies LoggerConfiguration['attributes']
}
