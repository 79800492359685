import { Currency } from '@backmarket/http-api'

const CURRENCIES_PRECISIONS: Record<Currency, number> = {
  AUD: 2,
  EUR: 2,
  GBP: 2,
  JPY: 0,
  SEK: 2,
  USD: 2,
}

const getCurrencyPrecision = (currency: string) => {
  const precision = CURRENCIES_PRECISIONS[currency as keyof typeof Currency]

  if (precision === undefined) {
    throw new Error(`Missing currency precision config for '${currency}'`)
  }

  return precision
}

export const formatPrice = ({
  amount,
  currency,
}: {
  amount: unknown
  currency: unknown
}) => {
  if (!amount) {
    return amount
  }

  return parseFloat(amount as string).toFixed(
    getCurrencyPrecision(currency as string),
  )
}
