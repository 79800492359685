// Inspired by https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
/* eslint-disable no-bitwise */
export const hashObjects = (
  ...objects: Array<Record<string, unknown>>
): string =>
  objects
    .map((o) => {
      // prevent key order from generating different hash
      const orderedObj = Object.keys(o)
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: o[key],
          }),
          {},
        )

      return JSON.stringify(orderedObj)
    })
    .join()
    .split('')
    .reduce((a, b) => {
      const hash = (a << 5) - a + b.charCodeAt(0)

      return hash & hash
    }, 0)
    .toString()

/* eslint-enable no-bitwise */
