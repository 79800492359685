import { createHttpEndpoint } from '../../utils'
import type { BuybackOrderConversationMetadata } from '../models'

/**
 * Get conversation message metadata
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/get-bm-buyback-conversation-order-metadata}
 */
export const getCustomerOrderMetadata =
  createHttpEndpoint<BuybackOrderConversationMetadata>({
    method: 'GET',
    operationId: 'getCustomerOrderMetadata',
    path: '/bm/buyback/v1/customer/orders/:id/messages/metadata',
  })
