import { MarketplaceCode } from '@backmarket/http-api'
import { toArray } from '@backmarket/utils/collection/toArray'
import { pickBy } from '@backmarket/utils/object/pickBy'
import type { MaybeArray } from '@backmarket/utils/types/MaybeArray'

import { MARKETS } from '../config'

/**
 * Get all markets.
 *
 * - If an array of marketplaces is given, it will return only the markets
 *   associated with these marketplaces.
 * - If no array of marketplaces is given, it will return all markets.
 *
 * @example
 * const markets = getAllMarkets()
 * // ^? { AT: { ... }, AU: { ... }, BE: { ... }, DE, { ... }, GB, { ... } }
 *
 * @example
 * const markets = getAllMarkets(MarketplaceCode.AP)
 * // ^? { AU: { ... }, JP: { ... } }
 *
 * @example
 * const markets = getAllMarkets([MarketplaceCode.AP, MarketplaceCode.NA])
 * // ^? { AU: { ... }, JP: { ... }, US: { ... } }
 */
export function getAllMarkets(
  marketplaces: MaybeArray<MarketplaceCode> = Object.values(MarketplaceCode),
): Partial<typeof MARKETS> {
  return pickBy(MARKETS, (market) => {
    return toArray(marketplaces).includes(market.marketplace)
  })
}
