import { defineNuxtPlugin } from '#app'
import { useHeadSafe } from '#imports'

/**
 * This mechanism is here to prevent E2E tests flakiness.
 * When "data-qa-ready=true" is present on the body we are sure the page is loaded, the hydration phase is finished and the page is fully interactive.
 */
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    useHeadSafe({
      bodyAttrs: {
        'data-qa-ready': 'true',
      },
    })
  })
})
