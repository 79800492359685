import { sleep } from '../async/sleep'

export const DEFAULT_ATTEMPT_COUNT = 5
export const DEFAULT_DELAY = 200

type RetryOptions = {
  count?: number
  delay?: number
  onFailedAttempt?: (
    error: unknown,
    failedAttempts: number,
    remainingAttempts: number,
  ) => void
}

/**
 * Attempts to get a successful response from `call` no more than `count` times
 * before returning an error. If the task is successful, the returned promise
 * will resolve with the same result as the task. If all attempts fail, the
 * returned promise will reject with the last error.
 *
 * @param call The function to call
 * @param options
 * @param options.count Maximum number of attempts
 * @param options.delay Delay between two attempts, in milliseconds
 * @param options.onFailedAttempt Function called after each unsuccessful attempt, before the delay
 */
export async function retry<T>(
  call: () => Promise<T> | T,
  options?: RetryOptions,
): Promise<T>
export async function retry<T>(
  call: () => Promise<T> | T,
  {
    count = DEFAULT_ATTEMPT_COUNT,
    delay = DEFAULT_DELAY,
    onFailedAttempt,
  }: RetryOptions = {},
  failedAttempts = 0,
): Promise<T> {
  try {
    return await call()
  } catch (error) {
    const newCount = count - 1
    const newFailedAttempts = failedAttempts + 1

    onFailedAttempt?.(error, newFailedAttempts, newCount)

    if (newCount === 0) {
      throw error
    }

    await sleep(delay)

    return retry(
      call,
      { count: newCount, delay, onFailedAttempt },

      // This argument is private
      // @ts-expect-error Expected 1-2 arguments, but got 3.
      newFailedAttempts,
    )
  }
}
