import type { RouteRecordRaw } from 'vue-router'

import { redirectInvalidUUID } from './middleware/redirectInvalidUUID'
import { PRODUCT } from './route-names'

export default (): RouteRecordRaw[] => {
  return [
    {
      component: () => import('./pages/Product.vue'),
      name: PRODUCT.HOME,
      path: 'p/:slugV2/:uuid',
      meta: {
        disableClientSideNavigation: () => false,
        middleware: [redirectInvalidUUID],
      },
    },
    {
      component: () =>
        import(
          './components/CustomizationFunnel/components/VisibleByVerizonDrawer/components/VisibleByVerizonDrawerBody/VisibleByVerizonDrawerBody.vue'
        ),
      name: PRODUCT.VISIBLE_BY_VERIZON_DRAWER,
      path: 'p/drawers/visible-by-verizon',
      meta: {
        layout: false,
      },
    },
    {
      name: PRODUCT.EXTERNAL_PAGE_VERIFIED_REFURBISHED,
      path: '/:locale/quality',
      component: () =>
        import(
          '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
        ),
      meta: {
        disableClientSideNavigation: () => true,
      },
    },
  ]
}
