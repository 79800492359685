import { useCookie, useRoute, useRuntimeConfig } from '#imports'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { COOKIE_SHOW_DEFAULT_TRANSLATIONS } from '../constants'
import type { I18n } from '../types'
import createCountryFormatter from '../utils/formatters/createCountryFormatter'
import createCurrencySymbolFormatter from '../utils/formatters/createCurrencySymbolFormatter'
import createDateFormatter from '../utils/formatters/createDateFormatter'
import createDistanceFormatter from '../utils/formatters/createDistanceFormatter'
import createHtmlFormatter from '../utils/formatters/createHtmlFormatter'
import createListFormatter from '../utils/formatters/createListFormatter'
import createLocaleFormatter from '../utils/formatters/createLocaleFormatter'
import createMessageFormatter from '../utils/formatters/createMessageFormatter'
import createNumberFormatter from '../utils/formatters/createNumberFormatter'
import createPriceFormatter from '../utils/formatters/createPriceFormatter'
import createRelativeDateFormatter from '../utils/formatters/createRelativeDateFormatter'

import { useI18nLocale } from './useI18nLocale'

/**
 * @returns A translate function with as attributes plenty of handy transformer functions.
 *
 * @example
 * const i18n = useI18n()
 *
 * i18n({ id: "something", defaultMessage: "the message"}) // 'the message'
 * i18n.language('fr-fr') // "French"
 * i18n.country('FR') // "France"
 * i18n.price({ amount: '10.0', currency: 'EUR' }) // '10.00€'
 * i18n.currencySymbol('EUR') // '€'
 * i18n.date(new Date()) // '01/01/2023'
 * i18n.relativeDate({ year: 12, month: 5, minutes: 2 }) //
 * i18n.distance(1000) //
 * i18n.list(['1', '2', '3']) // "1, 2, 3"
 */
export function useI18n() {
  const { market } = useMarketplace()
  const locale = useI18nLocale()
  const config = useRuntimeConfig().public.i18n
  const { defaultCurrency } = useMarketplace().market

  const showTranslationKeys = Boolean(useRoute().query?.showTranslationKeys)
  const showDefaultMessage = Boolean(
    useCookie(COOKIE_SHOW_DEFAULT_TRANSLATIONS).value,
  )

  const messageFormatter = createMessageFormatter({
    locale,
    showDefaultMessage,
    showTranslationKeys,
    currencyCode: defaultCurrency,
    shouldLogMissingTranslationValue: config.shouldLogMissingTranslationValue,
  }) as I18n

  const formatter = messageFormatter
  formatter.date = createDateFormatter(locale)
  formatter.relativeDate = createRelativeDateFormatter(locale)
  formatter.html = createHtmlFormatter(messageFormatter)
  formatter.number = createNumberFormatter(locale)
  formatter.country = createCountryFormatter(locale)
  formatter.language = createLocaleFormatter(locale)
  formatter.distance = createDistanceFormatter(locale, messageFormatter)
  formatter.list = createListFormatter(locale)
  formatter.price = createPriceFormatter(locale, market.defaultCurrency)
  formatter.currencySymbol = createCurrencySymbolFormatter(
    locale,
    market.defaultCurrency,
  )
  formatter.currentLocale = locale
  formatter.currentCurrency = market.defaultCurrency
  /**
   * @deprecated - use currencySymbol instead
   */
  formatter.currencySign = formatter.currencySymbol

  /**
   * @deprecated - use language instead
   */
  formatter.locale = formatter.language

  return formatter
}
