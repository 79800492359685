/**
 * Test if a character is a korean or japanese one
 * see code references
 * {@link https://www.loc.gov/marc/specifications/specchareacc/JapaneseHiraganaKatakana.html}
 * and
 * {@link https://www.loc.gov/marc/specifications/specchareacc/EastAsianIdeographs.html}
 * and
 * {@link https://www.loc.gov/marc/specifications/specchareacc/KoreanHangul.html}
 *
 * @param {String} character e.g 恵
 * @return {boolean}
 */

export function isCJKCharacter(character: string): boolean {
  // see Confluence page https://backmarket.atlassian.net/wiki/spaces/FRONT/pages/2889613669/Japanese+subsetting
  const japaneseRegexp =
    /[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9fff\u3400-\u4dbf\uf900-\ufaff]/

  const koreanRegexp = /[\uff9f\u3131-\uD79D]/

  return koreanRegexp.test(character) || japaneseRegexp.test(character)
}
