import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { createTTL } from '@backmarket/utils/date/createTTL'

export function createCookieConfig(durationInMonths: number) {
  const { market } = useMarketplace()
  const expirationDate = createTTL({ months: durationInMonths }).getTime()

  return {
    domain: market.domain,
    expires: new Date(expirationDate),
  }
}
