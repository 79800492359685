<template>
  <div class="mb-24 mt-8 flex pl-24 md:my-32 md:justify-center md:pl-0">
    <RevTabs label="Dasboard main navigation" variant="flexible">
      <RevTabItem
        :active="getActiveItem(DASHBOARD.MY_ORDERS.SELF)"
        data-qa="dashboard-navigation-my-orders"
        data-test="dashboard-navigation-my-orders"
        :label="i18n(translations.myOrders)"
        :to="resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF })"
      />

      <RevTabItem
        v-if="shouldDisplayMySalesLink"
        :active="getActiveItem(DASHBOARD.MY_SALES)"
        data-qa="dashboard-navigation-my-sales"
        data-test="dashboard-navigation-my-sales"
        :label="i18n(translations.myBuybackOffers)"
        :to="resolveLocalizedRoute({ name: DASHBOARD.MY_SALES })"
      />

      <RevTabItem
        :active="getActiveItem(DASHBOARD.KYC.PROFILE)"
        data-qa="dashboard-navigation-my-profile"
        data-test="dashboard-navigation-my-profile"
        :label="i18n(translations.myProfile)"
        :to="resolveLocalizedRoute({ name: DASHBOARD.KYC.PROFILE })"
      />

      <RevTabItem
        :active="getActiveItem(DASHBOARD.FAVORITES)"
        data-qa="dashboard-navigation-my-favorites"
        data-test="dashboard-navigation-my-favorites"
        :label="i18n(translations.favorites)"
        :to="resolveLocalizedRoute({ name: DASHBOARD.FAVORITES })"
      />

      <RevTabItem
        :active="getActiveItem(DASHBOARD.KYC.REFER_FRIEND)"
        data-qa="dashboard-navigation-referral-friend"
        data-test="dashboard-navigation-referral-friend"
        :label="i18n(translations.referFriend)"
        :to="resolveLocalizedRoute({ name: DASHBOARD.KYC.REFER_FRIEND })"
      />

      <RevTabItem
        :active="getActiveItem(DASHBOARD.KYC.OTHER)"
        data-qa="dashboard-navigation-other"
        data-test="dashboard-navigation-other"
        :label="i18n(translations.other)"
        :to="resolveLocalizedRoute({ name: DASHBOARD.KYC.OTHER })"
      />
    </RevTabs>
  </div>
</template>

<script lang="ts" setup>
import {
  useRoute,
  useRouteLocationWithLocale,
  useRuntimeConfig,
} from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevTabItem } from '@ds/components/TabItem'
import { RevTabs } from '@ds/components/Tabs'

import type { Features } from '@/features'

import { DASHBOARD } from '../../../routes'

import translations from './Navigation.translations'

const { features } = useMarketplace<Features>()
const i18n = useI18n()
const route = useRoute()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const runtimeConfig = useRuntimeConfig()

const { buyback } = features

const { KILL_MY_SALES_ACCESS } = runtimeConfig.public

const shouldDisplayMySalesLink = computed(() => {
  return buyback.enabled && !KILL_MY_SALES_ACCESS
})

function getActiveItem(routeName: string) {
  return route.name === routeName
}
</script>
