export type TTL = {
  months?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
}

/**
 * Date until when a data should exist.
 * (Offsets the current date with the interval provided as argument)
 *
 * Time To Live (TTL) = period of time a data should exist.
 */
export const createTTL = ({
  months = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
}: TTL) => {
  const newDate = new Date()
  newDate.setMonth(newDate.getMonth() + Number(months))
  newDate.setHours(newDate.getHours() + Number(hours))
  newDate.setMinutes(newDate.getMinutes() + Number(minutes))
  newDate.setSeconds(newDate.getSeconds() + Number(seconds))
  newDate.setMilliseconds(newDate.getMilliseconds() + Number(milliseconds))

  return newDate
}
