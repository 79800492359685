import { defineNuxtRouteMiddleware } from '#imports'

import { useHttpFromRoute } from './composables/useHttpFromRoute'
import { useLogger } from './composables/useLogger'

/**
 * Augment the logger context with information extracted from the route. They
 * are then injected into the `http` property of each and every emitted logs.
 */
export default defineNuxtRouteMiddleware((route) => {
  useLogger().appendGlobalContext({
    http: useHttpFromRoute(route),
  })
})
