import { initNoodleClient, push } from '@backmarket/front-noodle'

import type { EventData } from '../types'

interface NoodleEventDefinition {
  name: string
  version: string
}

export function sendEvent(
  { name, version }: NoodleEventDefinition,
  data: EventData,
) {
  if (process.server) {
    return
  }

  void push({ data, name, version })
}

interface NoodleUserContext {
  country: string
  locale: string
  sessionId: string
  visitorId: string
}

interface NoodleConfig {
  url: string
  environment:
    | 'prod-eu'
    | 'prod-us'
    | 'prod-ap'
    | 'preprod-eu'
    | 'preprod-us'
    | 'preprod-ap'
    | `staging-pr-${string}`
    | 'local'
  version: string
  /* The name of the service, 'front-office' for example. */
  service: string
}

export function initNoodle(
  userContext: NoodleUserContext,
  config: NoodleConfig,
) {
  initNoodleClient({
    app: {
      name: config.service,
      type: 'browser',
      version: config.version,
    },
    env: config.environment,
    url: config.url,
    userContext,
  })
}
