// This helpers does basically nothing. It accepts a string and returns it.
//
// It's used that way:
//
//    tw`text-center font-light`
//
// A few IDE extensions provide Tailwind autocompletion when typing from some
// HTML template. However, they do not provide autocompletion for arbirary JS
// code. This helper provides that.
//
// Once properly configured, IDE extensions might use this helper as a hook to
// provide autocompletion for Tailwind CSS classes from any JS environment.
//
// See: https://github.com/tailwindlabs/tailwindcss-intellisense/issues/129
export const tw = String.raw
