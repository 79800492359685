/**
 * Returns the `element` object or an empty object if the condition is not met.
 *
 * @example
 * const context = {
 *    foo: 'bar',
 *    ...insertIf(user, { name: user.name, email: user.email }),
 * }
 */
export function insertIf<T extends Record<string, unknown>>(
  condition: boolean,
  element: T,
): T | Record<string, never>

/**
 * Return the `element` array or an empty array if the condition is not met.
 *
 * @example
 * const categories = [
 * 'a',
 * 'b',
 *  ...insertIf(isCCategory, ['c'])
 * ]
 */
export function insertIf<T extends unknown[]>(
  condition: boolean,
  element: T,
): T | []

export function insertIf(condition: boolean, element: object | unknown[]) {
  const defaultValue = Array.isArray(element) ? [] : {}

  return condition ? element : defaultValue
}
