import { flatMap } from '@backmarket/utils/collection/flatMap'

import { META_TAGS, type MetaTypeKeys } from './constants'

type MetaObject = {
  hid: string
  name: string
  content: string
}

type MetaObjectInput = {
  tag: string
  value: string
}

type MetaTypeInput = {
  type: MetaTypeKeys
  value: string
}

/**
 * Builds a meta object for the given tag
 * @example
 * buildMetaObject({ tag: 'og:title', value: 'About us'})
 * // returns
 * {
 *    hid: 'og:title',
 *    name: 'og:title',
 *    content: 'About us',
 * }
 *
 * @returns Meta object used to build meta tag
 */
const buildMetaObject = ({ tag, value }: MetaObjectInput): MetaObject => {
  return {
    hid: tag,
    name: tag,
    content: value,
  }
}

/**
 * Builds the list of meta objects for a given type of meta
 *
 * @example
 * buildMetaForType({ type: 'title', value: 'About us'})
 * // returns
 * [
 *   {
 *     hid: 'og:title',
 *     name: 'og:title',
 *     content: 'About us',
 *   },
 *   {
 *     hid: 'twitter:title',
 *     name: 'twitter:title',
 *     content: 'About us',
 *   },
 * ]
 *
 * @returns {Meta[]} List of meta objects for a given type
 */
const buildMetaForType = ({ type, value }: MetaTypeInput): MetaObject[] =>
  META_TAGS[type].map((tag) => buildMetaObject({ tag, value }))

/**
 * Builds the list of meta objects for each type of meta
 * This list is used on the Nuxt function head() to generate the meta tags
 *
 * @example
 * getMetas({ title: 'About us', image: 'https://domain.com/img/relovethepreloved.png'})
 * // returns
 * [
 *   {
 *     hid: 'og:title',
 *     name: 'og:title',
 *     content: 'About us',
 *   },
 *   {
 *     hid: 'twitter:title',
 *     name: 'twitter:title',
 *     content: 'About us',
 *   },
 *   {
 *     hid: 'og:image',
 *     name: 'og:image',
 *     content: 'https://domain.com/img/relovethepreloved.png',
 *   },
 *   {
 *     hid: 'twitter:image',
 *     name: 'twitter:image',
 *     content: 'https://domain.com/img/relovethepreloved.png',
 *   },
 * ]
 *
 * @returns List of meta objects
 */
export const getMetas = (
  tags: Record<MetaTypeKeys | string, string> = {},
): MetaObject[] => {
  return flatMap(Object.entries(tags), ([type, value]) => {
    if (META_TAGS[type as MetaTypeKeys]) {
      return buildMetaForType({ type: type as MetaTypeKeys, value })
    }

    return buildMetaObject({ tag: type, value })
  })
}
