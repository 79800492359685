import { hash } from 'ohash'
import { isRef, reactive, unref } from 'vue'

import type { HttpEndpoint } from '@backmarket/http-api'

import type { UseHttpFetchRequestOptions } from '../types/UseHttpFetchRequestOptions'

/**
 * A function that generates a cache key to be use with useAsyncData.
 *
 * This key is unique per HTTP request, meaning that multiple calls will
 * return an identical string only if the same combination of endpoint + request
 * options are used.
 *
 * @see {@link https://nuxt.com/docs/api/composables/use-async-data#params useAsyncData params}
 * @private This function is not intended to be used outside of this Nuxt module.
 */
export function generateHttpRequestKey<T, B>(
  endpoint: HttpEndpoint<T, B>,
  requestOptions: UseHttpFetchRequestOptions<T, B>,
) {
  const { body, headers, pathParams, queryParams } = requestOptions

  return hash({
    requestOptions: {
      body: body instanceof FormData ? Array.from(body.entries()) : body,
      headers,
      pathParams,

      // By using 'reactive()' or 'unref()' here we ensure that all queryParams are unwrapped
      // eslint-disable-next-line no-nested-ternary
      queryParams: queryParams
        ? isRef(queryParams)
          ? unref(queryParams)
          : reactive(queryParams)
        : undefined,
    },
    settings: endpoint.settings,
  })
}
