export default {
  breadcrumb: {
    id: 'breadcrumb_aria_label',
    defaultMessage: 'Breadcrumb',
  },
  home: {
    id: 'landing_breadcrumb_home',
    defaultMessage: 'Home',
  },
}
