import { pickBy } from './pickBy'

/**
 * Return a new object including only the given keys extracted from the input.
 *
 * @example
 * const input = { a: 'A', b: 'B', c: 'C' }
 * const output = pick(input, ['a', 'b'])
 * //    ^? { a: 'A', b: 'B' }
 */
export function pick<Input extends Record<string, unknown>>(
  input: Input,
  keys: (keyof Input)[],
) {
  return pickBy(input, (_, key) => keys.includes(key))
}
