import { H3Event, getRequestHeader } from 'h3'

import { MarketCountryCode } from '@backmarket/http-api'

import { HEADER_CFWORKER_COUNTRY, HEADER_NGINX_COUNTRY } from '../constants'

import { getMarketFromDomain } from './getMarketFromDomain'

function isMarketCountryCode(value: string): value is MarketCountryCode {
  return value in MarketCountryCode
}

export function getRequestCountryCode(event: H3Event): MarketCountryCode {
  const cloudflare = getRequestHeader(event, HEADER_CFWORKER_COUNTRY)
  if (cloudflare && isMarketCountryCode(cloudflare)) {
    return cloudflare
  }

  const nginx = getRequestHeader(event, HEADER_NGINX_COUNTRY)
  if (nginx && isMarketCountryCode(nginx)) {
    return nginx
  }

  return getMarketFromDomain(event.node.req.headers.host ?? '').countryCode
}
