import { computed, ref } from 'vue'

import { useHttpStore } from '@backmarket/nuxt-module-http/useHttpStore'
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', () => {
  const httpStore = useHttpStore()

  /**
   * The currently authenticated user identifier.
   */
  const userId = ref<number>()

  /**
   * The currently authenticated user identifier but encrypted. This is useful
   * when passed to external partners (such as for student discounts) not to
   * leak internal Back Market data.
   */
  const encryptedUserId = ref<string>()

  /**
   * Returns a boolean indicating wether the user is authenticated or not.
   *
   * @example
   * const authStore = useAuthStore()
   * authStore.authenticated // boolean
   */
  const authenticated = computed(() => Boolean(userId.value))

  /**
   * Returns a boolean indicating wether we already tried to retrieve the
   * user information or not.
   *
   * Checking the `csrf` value is the best way to know that since it will
   * always be defined after the authentication request was fired.
   *
   * @example
   * const authStore = useAuthStore()
   * authStore.fetched // boolean
   */
  const fetched = computed(() => Boolean(httpStore.csrf))

  function $reset() {
    httpStore.$reset()
    userId.value = undefined
    encryptedUserId.value = undefined
  }

  return {
    userId,
    encryptedUserId,
    authenticated,
    fetched,
    $reset,
  }
})
