/*
  Not fully migrated, this is here to support the footer migration.
  Please check and migrate correctly
*/
import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

export const HELP_CENTER = {
  HOME: 'help_center',
}

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_HELP_CENTER } =
    useRuntimeConfig().public

  const isHelpCenterEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_HELP_CENTER as string
  )?.includes(market.countryCode)

  return [
    {
      name: HELP_CENTER.HOME,
      path: 'help',
      component: () => import('./Home.vue'),
      meta: {
        auth: {
          required: false,
        },
        // Required because help-center components are shown/hidden depending on auth state. it prevents UI flashes on full-page reload
        middleware: ['fetch-user'],
        layout: false,
        disableClientSideNavigation: () => !isHelpCenterEnabled,
      },
    },
  ]
}
