/**
 * Transform a locale to the RFC 4646 format.
 *
 * @see {@link https://www.rfc-editor.org/rfc/rfc4646 RFC 4646}
 */
export function toRfc4646Locale(locale: string) {
  const match = locale.match(
    /^(?<language>[a-zA-Z]{2}).(?<countryCode>[a-zA-Z]{2})$/,
  )

  return match
    ? `${match.groups?.language.toLowerCase()}-${match.groups?.countryCode.toUpperCase()}`
    : locale
}
