import { defineStore } from 'pinia'

type State = {
  /**
   * The CSRF token used to prevent cross site request forgery attacks when
   * communicating with APIs.
   */
  csrf?: string
}

export const useHttpStore = defineStore('http', {
  state(): State {
    return {
      csrf: undefined,
    }
  },
})
