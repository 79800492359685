import { useRuntimeConfig } from '#imports'

import type { MarketCountryCode, Marketplace } from '@backmarket/http-api'

import { MARKETS } from '../config'

/**
 * Get the marketplace associated to the given country code.
 *
 * This function is especially useful for usage in Nitro context as its sibling
 * composable `useMarketplace` will not work there. The tradeoff is that you
 * are expected to pass the country code manually.
 *
 * @example
 * const url = new URL(event.node.req.url)
 * const market = getMarketFromDomain(url.origin)
 * const marketplace = getMarketplace(market.countryCode)
 *
 * @warning
 * For all usage as part of Nuxt context, use `useMarketplace` instead.
 */
export function getMarketplace<
  Features extends Record<string, unknown> = Record<string, unknown>,
>(countryCode: MarketCountryCode) {
  const config = useRuntimeConfig().public.marketplace

  const market = MARKETS[countryCode]
  const marketplace: Marketplace = {
    marketplaceCode: market.marketplace,
    markets: Object.values(MARKETS).filter((m) => {
      return m.marketplace === market.marketplace
    }),
  }

  return {
    allMarkets: MARKETS,
    market,
    marketplace,
    features: config.features[countryCode] as Features,
  }
}
