import type { RouteLocationRaw } from 'vue-router'

import type { Location } from '@backmarket/design-system/types/Location'
import type { Link, LinkInternal } from '@backmarket/http-api'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { stringify } from '@backmarket/utils/query-string/stringify'

type NuxtOrHttpAPILink = Location | RouteLocationRaw

const isLink = (link: NuxtOrHttpAPILink): link is Link =>
  typeof link !== 'string' && 'type' in link

export function toNuxtLink(link: NuxtOrHttpAPILink): RouteLocationRaw {
  if (isLink(link)) {
    if (link.type === 'external') {
      return link.href
    }

    const {
      name,
      hash,
      params: unsafeParams,
      query,
      pattern,
    }: LinkInternal = link

    /**
     * Filtering out eventual extra parameters causing vue-router warnings ("Discarded invalid param(s)")
     * Example for faulty link object causing logs, where `id` and `slug` params are not used:
     *
     * {
     *   type: 'internal',
     *   name: 'product',
     *   pattern: '/:locale/p/:slugV2/:uuid',
     *   params: {
     *     id: '290057',
     *     slug: 'iphone-11-64-go-noir-debloque-tout-operateur-pas-cher',
     *     slugV2: 'iphone-11-64-go-noir-debloque-tout-operateur',
     *     uuid: '0106bb6f-3975-4794-b9a0-7d920669484c',
     *     locale: 'fr-fr',
     *   },
     *   query: {},
     *   hash: {},
     *   href: 'https://www.backmarket.fr/fr-fr/p/iphone-11-64-go-noir-debloque-tout-operateur/0106bb6f-3975-4794-b9a0-7d920669484c',
     * }
     *
     * @see https://github.com/vuejs/router/blob/main/packages/router/src/matcher/index.ts#L267
     */

    const filteredParams = pattern
      ? Object.keys(unsafeParams ?? {}).reduce((acc, param) => {
          // Support optional params in pattern like ':product?'
          const patternParts = pattern.split(/\??\//)

          if (patternParts.includes(`:${param}`)) {
            return { ...acc, [param]: unsafeParams[param] }
          }

          return acc
        }, {})
      : unsafeParams

    return {
      name,
      query,
      params: filteredParams,
      hash: isEmpty(hash) ? undefined : `#${stringify(hash)}`,
    }
  }

  return link as RouteLocationRaw
}
