import type { CustomizableCookie } from './types'

// These values are set in user's cookie storage and used by other stacks.
// Do not update them.
export const COOKIES: Record<CustomizableCookie, string> = {
  advertising: 'BM_Advertising',
  analytics: 'BM_Analytics',
  userExperience: 'BM_User_Experience',
}

export const GDPR_COOKIE_DURATION_IN_MONTHS = 6
