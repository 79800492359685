export default {
  footShort: {
    defaultMessage: '{distance} ft',
    id: 'formatting_unit_foot_short',
  },
  kilometerShort: {
    defaultMessage: '{distance} km',
    id: 'formatting_unit_kilometer_short',
  },
  meterShort: {
    defaultMessage: '{distance} m',
    id: 'formatting_unit_meter_short',
  },
  mileShort: {
    defaultMessage: '{distance} mi',
    id: 'formatting_unit_mile_short',
  },
}
