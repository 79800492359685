<script lang="ts">
import { type PropType, defineComponent, h, useSlots } from 'vue'

import { useI18n } from '../composables/useI18n'
import type { I18nDefinition } from '../types'

export default defineComponent({
  props: {
    definition: {
      type: Object as PropType<I18nDefinition>,
      required: true,
    },

    tag: {
      type: String,
      default: 'span',
    },

    values: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const i18n = useI18n()
    const slots = useSlots()

    return () => {
      const executedSlots = Object.entries(slots).reduce(
        (acc, [key, slot]) =>
          typeof slot === 'function' ? { ...acc, [key]: slot() } : acc,
        {},
      )
      const content = i18n.html(props.definition, {
        ...props.values,
        ...executedSlots,
      })

      if (content === null) {
        return null
      }

      return h(props.tag, content)
    }
  },
})
</script>
