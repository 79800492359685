import { useRuntimeConfig } from '#imports'
import { type H3Event, getCookie, getHeaders } from 'h3'

import { getRequestMarket } from '@backmarket/nuxt-module-marketplace/getRequestMarket'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

import {
  COOKIE_LANGUAGE,
  HEADER_CFWORKER_LOCALE,
  HEADER_NGINX_LOCALE,
} from '../constants'

/**
 * Extract the locale from the given `h3` event.
 *
 * @example
 * const locale = getRequestLocale(event)
 */
export function getRequestLocale(event: H3Event) {
  const config = useRuntimeConfig().public.i18n
  const cookie = getCookie(event, COOKIE_LANGUAGE)

  if (config.shouldLookForLocaleInCookie && cookie) {
    return toBcp47Locale(cookie)
  }

  const headers = getHeaders(event)
  const market = getRequestMarket(event)

  const locale =
    headers[HEADER_CFWORKER_LOCALE] ||
    headers[HEADER_NGINX_LOCALE] ||
    market.defaultLocale

  return toBcp47Locale(locale)
}
