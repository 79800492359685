import { defineNuxtPlugin, useNuxtApp } from '#imports'
import { watchEffect } from 'vue'

import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { BreakpointQueries } from '@backmarket/utils/dom/getCurrentBreakpoint'

/**
 * Track the breakpoints and send the data to the tracking module.
 */
export default defineNuxtPlugin(() => {
  const { $tracking } = useNuxtApp()

  const currentBreakpoint = useBreakpoint()
  watchEffect(() => {
    $tracking.trackResize({
      breakpointValue: currentBreakpoint.value,
      breakpointRule: BreakpointQueries[currentBreakpoint.value],
    })
  })
})
