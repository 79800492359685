import { createHttpEndpoint } from '../../utils'

import type { GetUserAuth } from './user.d'

/**
 * User authentication
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-cookie-consent-api/definition#operation/get-user-auth}
 */
export const getUserAuth = createHttpEndpoint<GetUserAuth.Response>({
  method: 'GET',
  operationId: 'get-user-auth',
  path: '/bm/user/auth',
  transformResponseToCamelCase: true,
})
