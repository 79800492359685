/**
 * Marketplace codes
 *
 * @see {@link https://github.com/BackMarket/api-models/blob/main/models/Marketplace.yaml OpenAPI specification}
 */
export enum MarketplaceCode {
  /*
   * Asia Pacific
   */
  AP = 'AP',

  /*
   * Europe
   */
  EU = 'EU',

  /*
   * North America
   */
  NA = 'NA',
}
