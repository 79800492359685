import { createHttpEndpoint } from '../../utils'
import type { BuybackOrderMessage } from '../models'

/**
 * Post a buyback messages on discussion for a given order
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Staff/operation/post-bm-buyback-staff-orders-messages}
 */
export const postStaffOrderMessage = createHttpEndpoint<BuybackOrderMessage>({
  method: 'POST',
  operationId: 'postStaffOrderMessage',
  path: '/bm/buyback/v1/staff/orders/:id/messages',
})
