export const FUNNEL_ERROR_MESSAGE = {
  GET_CATEGORIES: '[BUYBACK][FUNNEL] Error getting questions API (categories)',
  GET_QUESTIONS: '[BUYBACK][FUNNEL] Error getting questions API',
  POST_QUESTIONS: '[BUYBACK][FUNNEL] Error posting questions API',
  GET_OFFER: '[BUYBACK][FUNNEL] Error getting offer',
  POST_ORDER: '[BUYBACK][FUNNEL] Error posting buyback order',
  ADD_SWAP: '[BUYBACK][FUNNEL] Error adding buyback to cart',
  POST_ADDRESS: '[BUYBACK][FUNNEL] Error posting address',
  POST_BANK: '[BUYBACK][FUNNEL] Error posting bank details',
  BUYBACK_FUNNEL: '[BUYBACK][JS][FUNNEL] Error JS in buyback funnel',
}
