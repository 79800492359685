import { createHttpEndpoint } from '../../utils'
import type { BuybackOrderMessage } from '../models'

/**
 * Post a buyback messages on discussion for a given order
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Refurbisher/operation/post-bm-buyback-refurbisher-orders-messages}
 */
export const postSellerOrderMessage = createHttpEndpoint<BuybackOrderMessage>({
  method: 'POST',
  operationId: 'postSellerOrderMessage',
  path: '/bm/buyback/v1/refurbisher/orders/:id/messages',
})
