/**
 * Custom error thrown when a parameter is missing while resolving a path.
 *
 * @example
 * try {
 *   const path = resolvePath('/users/:userId', {})
 * } catch (error) {
 *   if (error instanceof ResolvePathMissingParamError) {
 *     // Do anything.
 *   }
 * }
 */
export class ResolvePathMissingParamError extends Error {
  name = 'ResolvePathMissingParamError'
}

/**
 * Resolve a dynamic path, applying the given parameters. The dynamic parts of
 * the path must be prefixed with `:`.
 *
 * @throws {ResolvePathMissingParamError}
 *
 * @example
 * const path = resolvePath('/users/:userId', { userId: 123 })
 * //    ^? '/users/123'
 */
export function resolvePath(
  path: string,
  params: Record<string, string | number> = {},
) {
  const parts = path.split('/').map((part) => {
    if (part.startsWith(':') === false) {
      return part
    }

    const key = part.slice(1)

    if (key in params === false) {
      throw new ResolvePathMissingParamError(`Parameter ${key} is missing`)
    }

    return params[key]
  })

  return parts.join('/')
}
