import { Country } from '../standards'

/**
 * Market country codes
 *
 * @see {@link https://github.com/BackMarket/api-models/blob/main/models/Market.yaml OpenAPI specification}
 */
export enum MarketCountryCode {
  AT = Country.AT,
  AU = Country.AU,
  BE = Country.BE,
  DE = Country.DE,
  ES = Country.ES,
  FI = Country.FI,
  FR = Country.FR,
  GB = Country.GB,
  GR = Country.GR,
  IE = Country.IE,
  IT = Country.IT,
  JP = Country.JP,
  NL = Country.NL,
  PT = Country.PT,
  SE = Country.SE,
  SK = Country.SK,
  US = Country.US,
}

export type CountryCodeValue = `${MarketCountryCode}`
