export const BUYBACK_SHIPPING_MODE = {
  KIT: 'BULLE',
  NEW_KIT: 'KIT',
  SELF_PACKAGED: 'COLISSIMO',
  NEW_SELF_PACKAGED: 'SELF_PACKAGED',
  QR_CODE: 'QR_CODE',
}

export const CATEGORIES = {
  SMARTPHONE: 'smartphone',
  AUDIO: 'audio',
  MACBOOK: 'macbook',
  CONSOLE: 'gaming_console',
  TABLET: 'tablet',
}
