import { useRuntimeConfig } from '#imports'

export function useAppHeaders() {
  const runtimeConfig = useRuntimeConfig()

  return {
    platform: 'web',
    version: runtimeConfig.public.VERSION as string,
  }
}
