import { createHttpEndpoint } from '../../utils'
import type { BuybackOrderConversationMetadata } from '../models'

/**
 * Get conversation message metadata
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Refurbisher/operation/get-bm-refurbisher-buyback-conversation-order-metadata}
 */
export const getSellerOrderMetadata =
  createHttpEndpoint<BuybackOrderConversationMetadata>({
    method: 'GET',
    operationId: 'getSellerOrderMetadata',
    path: '/bm/buyback/v1/refurbisher/orders/:id/messages/metadata',
  })
