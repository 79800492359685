import { MarketCountryCode } from '@backmarket/http-api'
import { type Location } from '@ds/types/Location'

import { useLinkResolver } from '~/composables/useLinkResolver'

type AlternateLink = {
  rel: 'alternate'
  hreflang: string
  href: string
}

/**
 * Composable to calculate the rel link to a given route in other markets to be used for SEO purposes
 *
 * @example
 * To calculate rel link the about-us route in the ES market
 * ```
 * const { getAlternateLink } = useAlternateLinks()
 * const alternateLink = getAlternateLink({
 *    link: { name: 'about-us' },
 *    countryCode: MarketCountryCode.ES,
 * })
 * ```
 *
 * @example
 * To calculate the rel link to the about-us route in all the markets
 * ```
 * const { getAlternateLinks } = useAlternateLinks()
 * const alternateLinks = getAlternateLink({
 *    link: { name: 'about-us' }
 * })
 * ```
 */
export function useAlternateLinks() {
  const { getLink } = useLinkResolver()

  function getAlternateLink({
    link,
    countryCode,
  }: {
    link: Location
    countryCode: MarketCountryCode
  }): AlternateLink | null {
    const linkObject = getLink({ link, countryCode })

    if (linkObject === null) {
      return null
    }

    return {
      rel: 'alternate',
      hreflang: linkObject.locale,
      href: linkObject.href,
    }
  }

  function getAlternateLinks({
    link,
    countryCodes = Object.values(MarketCountryCode),
  }: {
    link: Location
    countryCodes?: MarketCountryCode[]
  }): AlternateLink[] {
    return countryCodes
      .map((countryCode) => getAlternateLink({ link, countryCode }))
      .filter(Boolean) as AlternateLink[]
  }

  return {
    getAlternateLink,
    getAlternateLinks,
  }
}
