import type { RouteLocationNormalized } from 'vue-router'

import type {
  EventData,
  ModalData,
  PageViewData,
  ResizeData,
  SearchAnalyticsData,
  WebPerformanceData,
} from '../types'

import { sendEvent } from '.'

export function trackClick(data: EventData) {
  const EVENT_DEFINITION = Object.freeze({
    name: 'click',
    version: '0.0.1',
  })

  sendEvent(EVENT_DEFINITION, data)
}

export function trackPageView(to: RouteLocationNormalized) {
  if (!process.client) {
    return
  }

  const EVENT_DEFINITION = Object.freeze({
    name: 'page-view',
    version: '0.0.1',
  })

  const { referrer } = document
  const { host, hostname, href, pathname, hash, search } = window.location

  const pageType = String(to.name)

  sendEvent(EVENT_DEFINITION, {
    location: {
      hash,
      host,
      hostname,
      href,
      pathname,
      search,
    },
    pageType,
    referrer,
  })
}

export function trackRumView(data: PageViewData) {
  const RUM_VIEW = Object.freeze({
    version: '0.0.1',
    name: 'rum-view',
  })

  sendEvent(RUM_VIEW, data)
}

export function trackWebPerformance(data: PageViewData & WebPerformanceData) {
  const WEB_PERFORMANCE = Object.freeze({
    version: '0.0.1',
    name: 'web-performance',
  })

  sendEvent(WEB_PERFORMANCE, data)
}

export function trackModal(data: ModalData) {
  const MODAL_VIEW = Object.freeze({
    version: '0.0.1',
    name: 'modal',
  })

  sendEvent(MODAL_VIEW, data)
}

export function trackResize(data: ResizeData) {
  const RESIZE_DEFINITION = Object.freeze({
    version: '0.0.1',
    name: 'resize',
  })

  sendEvent(RESIZE_DEFINITION, data)
}

export function trackSearchAnalytics(data: SearchAnalyticsData) {
  const API = {
    version: '0.0.1',
    name: 'search-analytics',
  }

  sendEvent(API, data)
}

export function trackPaymentEvent(data: EventData) {
  const PAYMENT_FORM_SUBMIT_START = {
    version: '0.0.1',
    name: 'payment',
  }

  sendEvent(PAYMENT_FORM_SUBMIT_START, data)
}

export function trackPaymentResultSuccessEvent(data: EventData) {
  const API = {
    version: '0.0.1',
    name: 'paymentResultSuccess',
  }

  sendEvent(API, data)
}

export function trackPaymentResultFailureEvent(data: EventData) {
  const API = {
    version: '0.0.1',
    name: 'paymentResultFailure',
  }

  sendEvent(API, data)
}
