import { setBrowserCookie } from './setBrowserCookie'

/**
 * Delete the given cookie.
 *
 * @example
 * deleteBrowserCookie('my-cookie-name')
 */
export function deleteBrowserCookie(cookieName: string) {
  return setBrowserCookie(cookieName, '', {
    // One can't simply delete cookies. You have to make them expire, and the
    // simplest solution to achieve that is to set their expiration date to
    // some past date, like the 1st of January, 1970.
    expires: 'Thu, 01 Jan 1970 00:00:01 GMT',
    path: '/',
  })
}
