import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { ROUTES } from './route-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const {
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESET_PASSWORD,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_SET_NEW_PASSWORD,
  } = useRuntimeConfig().public

  const disableClientSideNavigation = !(
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESET_PASSWORD as string
  )?.includes(market.countryCode)

  const disableClientSideNavigationNewPassword = !(
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_SET_NEW_PASSWORD as string
  )?.includes(market.countryCode)

  return [
    {
      component: () => import('./pages/Login/Login.vue'),
      name: ROUTES.AUTH.LOGIN,
      path: '/auth/login',
      meta: {
        // The authentication flow takes place in two steps. First, we start the
        // flow and get redirected to the Identity stack. Second, we come back
        // from the Identity stack, and we retrieve actual authentication tokens.
        //
        // In the context of the migration, it means that the flow might start
        // from Front Office, and then ends up on Pastrami. However, while being
        // very similar, both implementations are not completely compatible.
        // So, we must ensure that the whole flow both starts and ends on the
        // same application.
        //
        // While Pastrami serves at least one page where we can authenticate
        // from (including links in the header), we can't use the Front Office
        // implementation, and thus must force a redirection to Pastrami.
        disableClientSideNavigation: () => true,
      },
    },
    {
      component: () => import('./pages/ResetPassword/ResetPassword.vue'),
      name: ROUTES.AUTH.RESET_PASSWORD,
      path: '/:locale/password-reset',
      meta: {
        disableClientSideNavigation: () => disableClientSideNavigation,
        layout: false,
      },
    },
    {
      component: () =>
        import('./pages/ResetPasswordConfirm/ResetPasswordConfirm.vue'),
      name: ROUTES.AUTH.RESET_PASSWORD_CONFIRM,
      path: '/:locale/password-reset/confirm',
      meta: {
        disableClientSideNavigation: () => disableClientSideNavigation,
        layout: false,
      },
    },
    {
      component: () => import('./pages/SetNewPassword/SetNewPassword.vue'),
      name: ROUTES.AUTH.SET_NEW_PASSWORD,
      path: '/:locale/password-reset/set-new/:userId/:token',
      meta: {
        disableClientSideNavigation: () =>
          disableClientSideNavigationNewPassword,
      },
    },
    {
      component: () =>
        import('./pages/SetNewPasswordExpired/SetNewPasswordExpired.vue'),
      name: ROUTES.AUTH.SET_NEW_PASSWORD_EXPIRED,
      path: '/:locale/password-reset/expired-reset',
      meta: {
        disableClientSideNavigation: () =>
          disableClientSideNavigationNewPassword,
      },
    },
  ]
}
