import type { RouteLocationNormalized } from 'vue-router'

import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type {
  CarouselData,
  DataLayerEvent,
  EventData,
  ModalData,
  ResizeData,
} from '../types'

import { productModel, productModelCollection } from './models/product'

import { pushEventToDataLayer, startCollectingEvents } from '.'

export function trackClick({ name, zone, value, position }: DataLayerEvent) {
  const event = 'bmClick'

  pushEventToDataLayer({
    event,
    name,
    position,
    value,
    zone,
  })
}

export function trackPageView(to: RouteLocationNormalized) {
  if (!process.client) {
    return
  }

  const { referrer } = document
  const event = 'bmPageView'

  pushEventToDataLayer({
    event,
    pageType: to.name,
    referrer,
  })
}

export function trackModal(data: ModalData) {
  const event = 'bmModal'

  pushEventToDataLayer({
    event,
    ...data,
  })
}

export function trackToast(data: EventData) {
  const event = 'bmToast'
  pushEventToDataLayer({
    event,
    ...data,
  })
}

export function trackCarouselClick(data: EventData) {
  const event = 'bmPromotionClick'

  pushEventToDataLayer({
    event,
    ...data,
  })
}

export function trackCarouselImpression(data: CarouselData) {
  const event = 'bmPromotionImp'

  const { currentSlide, creatives, name } = data

  const creative = creatives?.[currentSlide]?.creative ?? ''

  pushEventToDataLayer({
    event,
    creative,
    position: `${name}_${currentSlide}`,
  })
}

export function trackResize(data: ResizeData) {
  pushEventToDataLayer({
    event: 'bmResize',
    ...data,
  })
}

export function trackGDPR(data: EventData) {
  startCollectingEvents({
    event: 'bmGDPR',
    ...data,
  })
}

export function trackFormSubmit(data: EventData) {
  pushEventToDataLayer({
    event: 'bmSubmit',
    ...data,
  })
}

export function trackBuybackConfirmation(data: EventData) {
  pushEventToDataLayer({
    event: 'bmBuybackConfirmation',
    ...data,
  })
}
export function trackBuybackNoOffer(data: EventData) {
  pushEventToDataLayer({
    event: 'bmBuybackNoOffer',
    ...data,
  })
}

export function trackProductImpressionBatched(products: EventData[]) {
  pushEventToDataLayer({
    event: 'bmProductImpression',
    ecommerce: {
      impressions: productModelCollection(products),
    },
  })
}

export function trackProductImpression(product: EventData) {
  trackProductImpressionBatched([product])
}

export function trackAddToCart(product: EventData) {
  pushEventToDataLayer({
    event: 'addToCart',
    ecommerce: {
      add: {
        actionField: {
          action: 'add',
        },
        products: [productModel(product)],
      },
    },
  })
}

export function trackAddToCartBatch({
  products,
  list,
}: {
  products: EventData[]
  list: string
}) {
  pushEventToDataLayer({
    event: 'addToCart',
    ecommerce: {
      add: {
        actionField: {
          action: 'add',
          list,
        },
        products: productModelCollection(products),
      },
    },
  })
}

export function trackProductClick(product: EventData) {
  const { widgetId = null, list } = product
  pushEventToDataLayer({
    widget_id: widgetId,
    event: 'bmProductClick',
    ecommerce: {
      click: {
        actionField: {
          list,
        },
        products: [productModel(product)],
      },
    },
  })
}

export function trackProduct({ product, listings }: EventData) {
  pushEventToDataLayer({
    event: 'product',
    ecommerce: {
      detail: {
        actionField: {
          list: (product as EventData).list,
        },
        products: [productModel(product as EventData)],
        listings,
      },
    },
  })
}

export function trackSwapModal({ label, action, ...rest }: EventData) {
  pushEventToDataLayer({
    event: 'swap_modal',
    modal_name: 'swap',
    bm_action: action,
    bm_label: label,
    ...rest,
  })
}

export function trackSwap({ label, action, ...rest }: EventData) {
  pushEventToDataLayer({
    event: 'swap',
    modal_name: 'swap',
    bm_action: action || 'funnel > Step 1',
    bm_label: label,
    ...rest,
  })
}

export function trackLandingPage({ highlights, ...rest }: EventData) {
  const ecommerce = {
    ecommerce: {
      impressions: productModelCollection(highlights as EventData[]).filter(
        Boolean,
      ),
    },
  }

  pushEventToDataLayer({
    event: 'landing_page',
    ...insertIf(!isEmpty(highlights), ecommerce),
    ...rest,
  })
}

export function trackLandingBannerImpression({
  name,
  creative,
  position,
}: EventData) {
  pushEventToDataLayer({
    event: 'bmPromotionImp',
    ecommerce: {
      pomoView: {
        promotions: {
          name,
          creative,
          position,
        },
      },
    },
  })
}

export function trackLandingBannerClick({
  name,
  creative,
  position,
}: EventData) {
  pushEventToDataLayer({
    event: 'bmPromotionClick',
    ecommerce: {
      pomoView: {
        promotions: {
          name,
          creative,
          position,
        },
      },
    },
  })
}

export const trackPaymentResultSuccess = ({
  payment,
  products,
}: {
  payment: EventData
  products: EventData[]
}) => {
  pushEventToDataLayer({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          coupon: payment.discount,
          id: payment.orderId,
          revenue: payment.revenue,
          shipping: payment.shipping,
        },
        products: productModelCollection(products),
      },
    },
    code_parrain: payment.code_parrain,
    commission: payment.commission,
    '10Pct_FEE': payment['10Pct_FEE'],
    conversionValue: payment.revenue,
    months_since_last_purchase: payment.monthsSinceLastPurchase,
    orderId: payment.orderId,
    paymentMethod: payment.paymentMethod,
    swap_details: payment.swap_details,
    swap: payment.swap,
    totalQuantity: payment.totalQuantity,
    transactionDate: payment.transactionDate,
    transactionId: payment.transactionId,
    'customer-type': payment.customerType,
    revenueBM: payment.revenueBM,
    serviceFee: payment.serviceFee,
  })
}

export const trackPaymentResultFailure = ({
  paymentMethod,
  errorCode,
  errorReason,
}: EventData) => {
  pushEventToDataLayer({
    event: 'bmPaymentFail',
    paymentMethod,
    errorCode,
    errorReason,
  })
}

export const trackPromptNotificationImpression = ({ zone }: EventData) => {
  pushEventToDataLayer({
    event: 'promptNotificationImpression',
    zone,
  })
}

export const trackRemoveFromCart = ({ product }: EventData) => {
  pushEventToDataLayer({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [productModel(product as EventData)],
      },
    },
  })
}

export const trackFunnel = ({
  products,
  step,
  swap,
}: {
  products: EventData[]
  swap: boolean
  step: number
}) => {
  pushEventToDataLayer({
    event: 'funnel',
    ecommerce: {
      checkout: {
        actionField: {
          step,
        },
        products: productModelCollection(products),
      },
    },
    swap,
  })
}

export const trackResetPassword = () => {
  pushEventToDataLayer({ event: 'reset_pwd' })
}

export const trackResetPasswordSuccess = () => {
  pushEventToDataLayer({ event: 'reset_pwd_success' })
}
