import { navigateTo } from '#imports'
import type { RouteLocationNormalized } from 'vue-router'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { merge } from '@backmarket/utils/object/merge'
import { validate as validateUUID } from 'uuid'

// eslint-disable-next-line consistent-return
export function redirectInvalidUUID(to: RouteLocationNormalized) {
  const logger = useLogger()

  const productId = Array.isArray(to.params.uuid)
    ? to.params.uuid[0]
    : to.params.uuid
  const slug = Array.isArray(to.params.slugV2)
    ? to.params.slugV2[0]
    : to.params.slugV2

  // validateUUID is a bit stricter than our regex above… and than the validation in badoom. For instance
  // `29512ad0-ac02-7668-3c97-e2b45a4ae07a` is not valid using validateUUID but OK using our regex, due to
  // the first number of the third block. Let's avoid redirecting to the same page and breaking everything.
  // A invalid uuid leads to 404 errors.
  // If a part of the url is encoded, the uuid gets corrupted.
  // ex: c84deba7-b0dd-4dc3-977e-61cc3530a540%23l=10
  // Here we fix the uuid and we redirect with a 301.
  if (!validateUUID(productId)) {
    const uuid = productId.match(
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
    )

    if (uuid !== null && productId !== uuid[0]) {
      logger.info('Product invalid uuid redirection', {
        requestedUUID: productId,
        redirectedTo: uuid[0],
      })

      const redirect = merge({}, to, {
        params: {
          slugV2: slug,
          uuid: uuid[0],
        },
      })

      return navigateTo(redirect, { redirectCode: 301 })
    }
  }
}
