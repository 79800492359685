import revive_payload_client_YTGlmQWWT5 from "/var/www/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/var/www/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/var/www/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/var/www/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/var/www/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Ib6KnAhqrq from "/var/www/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/apps/front-office/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/var/www/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import injectQaReadyAttribute_ESWTWoOqNK from "/var/www/packages/nuxt-module-qa/src/runtime/plugins/injectQaReadyAttribute.ts";
import plugin_client_Frge92odpj from "/var/www/packages/nuxt-module-http-v2/src/runtime/plugin.client.ts";
import plugin_g7djW260Qp from "/var/www/packages/nuxt-module-logger/src/runtime/plugin.ts";
import plugin_WDK3UYjkJW from "/var/www/packages/nuxt-module-identification/src/runtime/plugin.ts";
import tracking_PVOGIQZhrr from "/var/www/packages/nuxt-module-tracking/src/runtime/plugins/tracking.ts";
import plugin_BapcHuHykt from "/var/www/packages/nuxt-module-i18n/src/runtime/plugin.ts";
import chunk_reload_client_SeG0EjL5Ec from "/var/www/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cookiesModal_SM4C0cLa6N from "/var/www/packages/nuxt-module-tracking/src/runtime/plugins/cookiesModal.ts";
import _01_design_system_hx3UhUPoFW from "/var/www/node_modules/@backmarket/nuxt-layer-design-system/plugins/01.design-system.ts";
import _02_tracking_client_tLNC9tJ2ej from "/var/www/node_modules/@backmarket/nuxt-layer-design-system/plugins/02.tracking.client.ts";
import _02_trackResize_client_WGREGLnYM4 from "/var/www/node_modules/@backmarket/nuxt-layer-base/plugins/02.trackResize.client.ts";
import error_qPrTAbsZlj from "/var/www/node_modules/@backmarket/nuxt-layer-base/plugins/error.ts";
import trackPageView_client_LyIHgizdIF from "/var/www/node_modules/@backmarket/nuxt-layer-base/plugins/trackPageView.client.ts";
import brazeServiceWorker_client_3HhmMRVR7Q from "/var/www/apps/front-office/plugins/brazeServiceWorker.client.ts";
import user_client_pgylpKXiFi from "/var/www/apps/front-office/plugins/user.client.ts";
import xstate_inspector_client_jm5Zb59c6t from "/var/www/apps/front-office/plugins/xstate-inspector.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  injectQaReadyAttribute_ESWTWoOqNK,
  plugin_client_Frge92odpj,
  plugin_g7djW260Qp,
  plugin_WDK3UYjkJW,
  tracking_PVOGIQZhrr,
  plugin_BapcHuHykt,
  chunk_reload_client_SeG0EjL5Ec,
  cookiesModal_SM4C0cLa6N,
  _01_design_system_hx3UhUPoFW,
  _02_tracking_client_tLNC9tJ2ej,
  _02_trackResize_client_WGREGLnYM4,
  error_qPrTAbsZlj,
  trackPageView_client_LyIHgizdIF,
  brazeServiceWorker_client_3HhmMRVR7Q,
  user_client_pgylpKXiFi,
  xstate_inspector_client_jm5Zb59c6t
]