/**
 * Code that represents a human language, that consists in a underscore-separated
 * list of:
 * 1. language code, in {@link https://en.wikipedia.org/wiki/ISO_639-1 ISO 639-1}
 * 2. country code, in {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 ISO 3166-1 alpha 2}
 *
 * @example 'en_US'
 *
 * @see {@link https://devportal.backmarket.io/docs/default/component/api-guidelines/rules/data_types/55/ MUST use language code format}
 * @see {@link https://devportal.backmarket.io/docs/default/component/api-guidelines/rules/data_types/56/ MUST use country code format}
 */
export enum Locale {
  de_AT = 'de_AT',
  de_DE = 'de_DE',
  el_GR = 'el_GR',
  en_GB = 'en_GB',
  en_AU = 'en_AU',
  en_IE = 'en_IE',
  en_US = 'en_US',
  es_ES = 'es_ES',
  fi_FI = 'fi_FI',
  fr_BE = 'fr_BE',
  fr_FR = 'fr_FR',
  it_IT = 'it_IT',
  ja_JP = 'ja_JP',
  ko_KR = 'ko_KR',
  nl_NL = 'nl_NL',
  pt_PT = 'pt_PT',
  sv_SE = 'sv_SE',
  sk_SK = 'sk_SK',
}
