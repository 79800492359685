import { Locale } from '@backmarket/http-api'
import { round } from '@backmarket/utils/math/round'

import translations from '../../translations/distances.translations'
import type { I18nMessageFormatter } from '../../types'

/**
 * An opinionated formatter to convert meters into humanly digestable distances.
 *
 * Switches between imperial and metric depending on the locale.
 *
 * Switches between feet to miles and meters to kilometers depending on what
 * is the most sensible.
 *
 * @example
 * createDistanceFormatter('fr-FR', messageFormatter)(1000)
 * > "1 km"
 *
 * createDistanceFormatter('fr-FR', messageFormatter)(300)
 * > "300 m"
 */
export default function createDistanceFormatter(
  locale: Locale,
  messageFormatter: I18nMessageFormatter,
) {
  const MILE_PER_METER = 0.000621371
  const FEET_PER_METER = 3.28084
  // number of feet where we switch, should correspond to 0.1 miles
  const MAX_FEET_CUTOFF = 528
  const useImperial = new Set([
    Locale.en_US,
    Locale.en_GB,
    // 'en-lr',
    // 'my-mm',
  ]).has(locale)

  // NOTE: not using `Intl.NumberFormat({style: 'unit'})` for formatters
  // due to Safari 13/14 support
  function feetFormatter(distance: number) {
    return messageFormatter(translations.footShort, { distance })
  }
  function mileFormatter(distance: number) {
    return messageFormatter(translations.mileShort, { distance })
  }
  function meterFormatter(distance: number) {
    return messageFormatter(translations.meterShort, { distance })
  }
  function kilometerFormatter(distance: number) {
    return messageFormatter(translations.kilometerShort, { distance })
  }

  return (meters: number) => {
    if (useImperial) {
      const roundedFeet = round(meters * FEET_PER_METER, -1)

      return roundedFeet < MAX_FEET_CUTOFF
        ? feetFormatter(roundedFeet)
        : mileFormatter(round(meters * MILE_PER_METER, 1))
    }

    const roundedMeters = round(meters, -1)

    if (roundedMeters < 1000) {
      return meterFormatter(roundedMeters)
    }
    if (roundedMeters < 10000) {
      return kilometerFormatter(round(roundedMeters / 1000, 1))
    }

    return kilometerFormatter(round(roundedMeters / 1000, 0))
  }
}
