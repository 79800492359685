<template>
  <NuxtLayout>
    <Error4xx
      v-if="is4XX"
      :button-route="resolveLocalizedRoute({ name: HOME })"
      :error="error"
    />
    <Error500 v-if="!is4XX" :error="error" />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { type NuxtError } from '#app'
import { computed } from 'vue'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { HOME } from '~/scopes/home/route-names'

const resolveLocalizedRoute = useRouteLocationWithLocale()

const props = defineProps<{ error: NuxtError }>()

const is4XX = computed(() => [404, 410, 422].includes(props.error.statusCode))
</script>
