/**
 * 3-letters code that represent a currency, as described in
 * {@link https://en.wikipedia.org/wiki/ISO_4217 ISO 4217}.
 *
 * @see {@link https://devportal.backmarket.io/docs/default/component/api-guidelines/rules/data_types/57/ MUST use currency format}
 */
export enum Currency {
  AUD = 'AUD',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  SEK = 'SEK',
  USD = 'USD',
}
