import { getCustomerOrderMessages } from '@backmarket/http-api/src/api-specs-buyback/customer/getMessages'
import { getCustomerOrderMetadata } from '@backmarket/http-api/src/api-specs-buyback/customer/getMetadata'
import { postCustomerOrderMessage } from '@backmarket/http-api/src/api-specs-buyback/customer/postMessage'
import { getSellerOrderMessages } from '@backmarket/http-api/src/api-specs-buyback/seller/getMessages'
import { getSellerOrderMetadata } from '@backmarket/http-api/src/api-specs-buyback/seller/getMetadata'
import { postSellerOrderMessage } from '@backmarket/http-api/src/api-specs-buyback/seller/postMessage'
import { getStaffOrderMessages } from '@backmarket/http-api/src/api-specs-buyback/staff/getMessages'
import { getStaffOrderMetadata } from '@backmarket/http-api/src/api-specs-buyback/staff/getMetadata'
import { postStaffOrderMessage } from '@backmarket/http-api/src/api-specs-buyback/staff/postMessage'

export const ROLES = {
  CUSTOMER: 'CUSTOMER',
  STAFF: 'STAFF',
  MERCHANT: 'MERCHANT',
  AUTO_MESSAGE: 'AUTO_MESSAGE',
} as const

export type Role = keyof typeof ROLES

export const getConversationsEndoints = (role: Role) => {
  switch (role) {
    case ROLES.STAFF:
    case ROLES.AUTO_MESSAGE:
      return {
        getConversation: getStaffOrderMessages,
        getMetadata: getStaffOrderMetadata,
        postMessage: postStaffOrderMessage,
      }
    case ROLES.MERCHANT:
      return {
        getConversation: getSellerOrderMessages,
        getMetadata: getSellerOrderMetadata,
        postMessage: postSellerOrderMessage,
      }
    case ROLES.CUSTOMER:
    default:
      return {
        getConversation: getCustomerOrderMessages,
        getMetadata: getCustomerOrderMetadata,
        postMessage: postCustomerOrderMessage,
      }
  }
}
