import { tw } from '@backmarket/utils/string/tw'

import type { BackgroundProps } from '../../shared-components/Background/Background.types'
import { hasBackground } from '../../shared-components/Background/Background.utils'

export function getPaddingClass(options: Pick<BackgroundProps, 'background'>) {
  const horizontalPadding = tw`px-24 md:px-72`
  if (!hasBackground(options)) {
    return tw`${horizontalPadding} py-0`
  }

  return 'image' in options.background
    ? tw`${horizontalPadding} py-32 md:py-72`
    : tw`${horizontalPadding} py-32`
}
