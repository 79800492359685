import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import type { Features } from '../../features'

export function useTrustmark() {
  const {
    features: { trustmark, trustpilotConfig, trustedShopsId = '' },
  } = useMarketplace<Features>()

  const withTrustedShops =
    trustmark === 'trusted-shops' || trustmark === 'confianza-online'

  const withTrustpilot = trustmark === 'trustpilot'

  const trustmarkConfig = withTrustpilot
    ? trustpilotConfig
    : {
        id: trustedShopsId,
      }

  return {
    withTrustedShops,
    withTrustpilot,
    trustmarkConfig,
  }
}
