import { MarketCountryCode } from '@backmarket/http-api'
import { type CMSPageType } from '@backmarket/http-api/src/api-specs-content'

import { DISCOUNT } from '~/scopes/discount/routes-names'
import { HOME } from '~/scopes/home/route-names'
import { ROUTES as PLP_ROUTES } from '~/scopes/product-list/route-names'

import { CMS } from '../routes-names'

/**
 * Mapping between the route name and its corresponding page type in the CMS.
 * The page type is provided to the content service call.
 */
export const CMS_PAGE_TYPE_BY_ROUTE_NAME = {
  [CMS.ARTICLE]: 'article',
  [CMS.BACK_STORIES]: 'back-stories',
  [CMS.BACK_STORIES_CATEGORY]: 'back-stories',
  [CMS.BRAND_STORE]: 'brand-store',
  [CMS.BRAND_STORE_CATEGORY]: 'brand-store',
  [CMS.BUYBACK]: 'buyback',
  [CMS.BUYBACK_CATEGORY]: 'buyback',
  [CMS.E2E_PAGE]: 'end2EndTests',
  [CMS.EVENT]: 'event-page',
  [CMS.GIFT_FINDER_EVENT]: 'gift-finder',
  [CMS.GIFT_FINDER_MAIN]: 'gift-finder',
  [CMS.LEGAL_PREVIOUS_VERSION]: 'legal-document',
  [CMS.LEGAL_PAGE]: 'legal-page',
  [CMS.REFER_FRIEND]: 'core',
  [CMS.SELLER]: 'seller',
  [CMS.STATEMENT]: 'core',
  [CMS.SERVICE]: 'service',
  [DISCOUNT.MILITARY]: 'core',
  [DISCOUNT.STUDENT]: 'core',
  [HOME]: 'core',
  [PLP_ROUTES.UNIVERSE]: 'universe-page',
} satisfies Record<string, CMSPageType>

export const PAGE_NAME_BY_PLP_UUID: Partial<
  Record<MarketCountryCode, Record<string, string>>
> = {
  [MarketCountryCode.DE]: {
    '630dab14-5051-49b9-bc7b-bb20876d4850': 'laptops',
    '6c290010-c0c2-47a4-b68a-ac2ec2b64dca': 'smartphones',
  },
  [MarketCountryCode.ES]: {
    '630dab14-5051-49b9-bc7b-bb20876d4850': 'laptops',
  },
  [MarketCountryCode.FR]: {
    '630dab14-5051-49b9-bc7b-bb20876d4850': 'laptops',
    '6c290010-c0c2-47a4-b68a-ac2ec2b64dca': 'smartphones',
  },
  [MarketCountryCode.GB]: {
    '630dab14-5051-49b9-bc7b-bb20876d4850': 'laptops',
    '6c290010-c0c2-47a4-b68a-ac2ec2b64dca': 'smartphones',
  },
  [MarketCountryCode.US]: {
    '02661471-d1ce-4d43-88e3-4bc4b7d4c8d6': 'laptops',
    '0744fd27-8605-465d-8691-3b6dffda5969': 'smartphones',
  },
}

export const OWNER_BY_PAGE_TYPE = {
  article: '[ACQ]',
  'back-stories': '[COM]',
  'brand-store': '[LIF]',
  buyback: '[BBACK]',
  core: '[LIF]',
  end2EndTests: '[COM]',
  'event-page': '[LIF]',
  'gift-finder': '[ACQ]',
  'legal-document': '[ACQ]',
  'legal-page': '[ACQ]',
  seller: '[SELLER]',
  service: '[B2CS]',
  'universe-page': '[SR]',
} satisfies Record<CMSPageType, string>

export const PAGE_TYPE_WITH_SPECIFIC_FIELDS = [
  CMS_PAGE_TYPE_BY_ROUTE_NAME[CMS.LEGAL_PAGE],
  CMS_PAGE_TYPE_BY_ROUTE_NAME[CMS.LEGAL_PREVIOUS_VERSION],
]

export const PAGE_TYPE_WITH_NO_PARAMS_CORRECTION = [
  CMS_PAGE_TYPE_BY_ROUTE_NAME[CMS.LEGAL_PAGE],
  CMS_PAGE_TYPE_BY_ROUTE_NAME[CMS.LEGAL_PREVIOUS_VERSION],
]
