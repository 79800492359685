import { useCookie, useRequestHeaders } from '#imports'

import { createIdentificationUniqueId } from '../helpers/createIdentificationUniqueId'

// Must be identical to what is defined on the experiments side.
// See: https://github.com/BackMarket/experiments-worker/blob/dev/src/constants/headers.ts
const HEADER_NAME = 'bm-session-id'

// Must be identical to what is defined on the experiments side.
// See: https://github.com/BackMarket/experiments-worker/blob/dev/src/constants/cookies.ts.
const COOKIE_NAME = 'session_id'

// 30 minutes
const COOKIE_EXPIRATION_MS = 1000 * 60 * 30

type Settings = {
  /** Refresh the cookie to extend its expiration date. */
  forceRefresh: boolean
}

/**
 * Retrieve the session ID from the HTTP headers or cookies. If no value is
 * found, a new one is generated and stored automatically.
 *
 * @example
 * const sessionId = useSessionId()
 */
export function useSessionId(
  settings: Settings = { forceRefresh: false },
): string {
  const headers = useRequestHeaders()

  // First, check for any server-side HTTP headers. In preprod and production,
  // this header is added by the experiments worker. In such context, this
  // module must only read the value, and let the worker orchestrate the rest.
  // See https://github.com/BackMarket/experiments-worker/blob/dev/src/index.ts.
  if (headers[HEADER_NAME]) {
    return headers[HEADER_NAME]
  }

  // If the server-side HTTP header is not found, or if we're client-side, try
  // reading the value from the cookie. In DSE and local, the HTTP header is
  // never set (because there is no experiments worker), so this is the main
  // way of reading the session ID.
  //
  // In case there is no cookie either (because it's the first visit, or the
  // cookies were recently cleared), then generate a brand new one. When a
  // new value is generated, Nuxt automatically adds a "Set-Cookie" header
  // to the server response, so that the value gets saved client-side.
  const cookie = useCookie(COOKIE_NAME, {
    default: () => createIdentificationUniqueId(),
    expires: new Date(Date.now() + COOKIE_EXPIRATION_MS),
    secure: true,
  })

  if (settings.forceRefresh) {
    const backup = cookie.value
    cookie.value = ''
    cookie.value = backup
  }

  // We extract the ref value because we do not want this value to be reactive.
  return cookie.value
}
