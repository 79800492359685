import { useRuntimeConfig } from '#imports'

import { addTrailingSlash } from '@backmarket/utils/url/addTrailingSlash'
import { removeLeadingSlash } from '@backmarket/utils/url/removeLeadingSlash'

/**
 * Prepend the CDN base URL to the given path
 * @example
 * toPublicPath('/img/logo.svg')
 * toPublicPath('img/logo.svg')
 */
export function toPublicPath(path: string) {
  // Since by nature, `useRuntimeConfig` is not really a composable but more of an 'utils',
  // we decided to create a toPublicPath 'utils' rather than a composable for convenience.
  const {
    app: { cdnURL },
  } = useRuntimeConfig()

  return cdnURL
    ? new URL(removeLeadingSlash(path), addTrailingSlash(cdnURL)).href
    : path
}
