/**
 * @see https://backmarket.atlassian.net/wiki/x/HgA5lQ
 */
export type ErrorPayloadV1 = {
  error: {
    code: string
    message: string
  }
  data?: {
    fields: {
      [key: string]: Array<string>
    }
  }
}

/**
 * @see https://github.com/BackMarket/api-design-guidelines/blob/master/models/API-Model-Error.md
 */
export type ErrorPayloadV2 = {
  errors: Array<{
    /**
     * A human-readable and machine-parseable unique error code.
     */
    code: string

    /**
     * A human-readable message detailing the error.
     */
    message: string

    /**
     * The field in the resource impacted by the error.
     *
     * @example
     * email
     */
    target?: string

    /**
     * A link to a documentation page detailing the error code and ways to
     * circumvent it.
     */
    docLink?: string

    /**
     * Additional data that can help diagnose the error.
     */
    data?: {
      [key: string]: unknown
    }
  }>
}

/**
 * @see https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml
 * @see https://github.com/BackMarket/api-models/blob/main/models/ProblemValidation.yaml
 */
export type ErrorPayloadV3 = {
  /**
   * Unique problem identifier.
   *
   * @example
   * /errors/authorization-failed
   *
   * @see https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml#L5
   */
  type: string

  /**
   * A short, human-readable summary of the problem type.
   *
   * It should not change from occurrence to occurrence of the problem except
   * for purposes of localization.
   *
   * @example
   * Unable to process payment
   *
   * @see https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml#L13
   */
  title: string

  /**
   * The HTTP status code generated by the origin server for this occurrence
   * of the problem.
   *
   * @example
   * 503
   *
   * @see https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml#L19
   */
  status: number

  /**
   * A human-readable explanation specific to this occurrence of the problem.
   *
   * @example
   * Payment authorization failed because your card is expired.
   *
   * @see https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml#L28
   */
  detail?: string

  /**
   * An absolute URI that identifies the specific occurrence of the problem.
   *
   * @example
   * https://example.org/error/condition
   *
   * @see https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml#L34
   */
  instance?: string

  /**
   * @see https://github.com/BackMarket/api-models/blob/main/models/ProblemValidation.yaml#L6
   */
  errors: Array<{
    /**
     * @example
     * /errors/email-conflict
     *
     * @see https://github.com/BackMarket/api-models/blob/main/models/ProblemValidationErrorMember.yaml#L14
     */
    type: string

    /**
     * @example
     * Email conflict error
     *
     * @see https://github.com/BackMarket/api-models/blob/main/models/ProblemValidationErrorMember.yaml#L18
     */
    title: string

    /**
     * @example
     * email
     *
     * @see https://github.com/BackMarket/api-models/blob/main/models/ProblemValidationErrorMember.yaml#L26
     */
    target: string

    /**
     * @example
     * This email is already taken
     *
     * @see https://github.com/BackMarket/api-models/blob/main/models/ProblemValidationErrorMember.yaml#L22
     */
    detail?: string

    /**
     * @see https://github.com/BackMarket/api-models/blob/main/models/ProblemValidationErrorMember.yaml#L30
     */
    context?: {
      [key: string]: unknown
    }
  }>
}

export type ErrorPayloadNormalized = ErrorPayloadV3 & {
  errorResponseVersion: string
}

const ERROR_STATUS_UNKNOWN = 0
const ERROR_TARGET_UNKNOWN = 'UnknownTarget'
const ERROR_TITLE_UNKNOWN = 'UnknownTitle'
const ERROR_TYPE_UNKNOWN = '/unknown/type'

const ERROR_RESPONSE_VERSION = {
  UNKNOWN: 'ErrorResponseVersionUnknown',
  V1: 'ErrorResponseVersionV1',
  V2: 'ErrorResponseVersionV2',
  V3: 'ErrorResponseVersionV3',
} as const

/**
 * Assert that the given payload is a v1 error payload.
 */
function isErrorResponseV1(payload: unknown): payload is ErrorPayloadV1 {
  return (
    // Check that payload is an actual object, not null.
    typeof payload === 'object' &&
    payload !== null &&
    // Check that payload.data is an actual object, not null.
    'data' in payload &&
    typeof payload.data === 'object' &&
    payload.data !== null &&
    // Check that payload.data.fields is an actual object, not null.
    'fields' in payload.data &&
    typeof payload.data.fields === 'object' &&
    payload.data.fields !== null
  )
}

/**
 * Assert that the given payload is a v2 error payload.
 */
function isErrorResponseV2(payload: unknown): payload is ErrorPayloadV2 {
  return (
    // Check that payload is an actual object, not null.
    typeof payload === 'object' &&
    payload !== null &&
    // Check that payload.errors is an array.
    'errors' in payload &&
    Array.isArray(payload.errors)
  )
}

/**
 * Assert that the given payload is a v3 error payload.
 */
function isErrorResponseV3(payload: unknown): payload is ErrorPayloadV3 {
  return (
    // Check that payload is an actual object, not null.
    typeof payload === 'object' &&
    payload !== null &&
    // Check that payload.title is a string.
    'title' in payload &&
    typeof payload.title === 'string'
  )
}

/**
 * Normalize a v1 error payload.
 */
function fromErrorResponseV1(payload: ErrorPayloadV1): ErrorPayloadNormalized {
  const errors = payload.data?.fields || {}

  return {
    status: ERROR_STATUS_UNKNOWN,
    title: payload.error.message,
    type: payload.error.code,
    errors: Object.entries(errors).map(([key, value]) => ({
      type: '',
      title: value[0],
      target: key,
      context: { [key]: value },
    })),
    errorResponseVersion: ERROR_RESPONSE_VERSION.V1,
  }
}

/**
 * Normalize a v2 error payload.
 *
 * @see https://github.com/BackMarket/api-design-guidelines/blob/master/models/API-Model-Error.md
 */
function fromErrorResponseV2(payload: ErrorPayloadV2): ErrorPayloadNormalized {
  const firstError = payload.errors[0] || {}

  return {
    errors: payload.errors.map((error) => ({
      type: error.code,
      title: error.message,
      target: error.target || ERROR_TARGET_UNKNOWN,
      detail: error.docLink,
      context: error,
    })),
    status: ERROR_STATUS_UNKNOWN,
    title: firstError.message,
    type: firstError.code,
    errorResponseVersion: ERROR_RESPONSE_VERSION.V2,
  }
}

/**
 * Normalize a v3 error payload.
 *
 * @see https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml
 */
function fromErrorResponseV3(payload: ErrorPayloadV3): ErrorPayloadNormalized {
  return {
    ...payload,
    errorResponseVersion: ERROR_RESPONSE_VERSION.V3,
  }
}

/**
 * Normalize an unknown error payload.
 */
function fromErrorResponseUnknown(payload: unknown): ErrorPayloadNormalized {
  return {
    errors: [
      {
        type: ERROR_TYPE_UNKNOWN,
        title: ERROR_TITLE_UNKNOWN,
        target: ERROR_TARGET_UNKNOWN,
        context: { payload },
      },
    ],
    status: ERROR_STATUS_UNKNOWN,
    title: ERROR_TITLE_UNKNOWN,
    type: ERROR_TYPE_UNKNOWN,
    errorResponseVersion: ERROR_RESPONSE_VERSION.UNKNOWN,
  }
}

export function fromAnyError(input: unknown): ErrorPayloadNormalized {
  if (isErrorResponseV3(input)) {
    return fromErrorResponseV3(input)
  }

  if (isErrorResponseV2(input)) {
    return fromErrorResponseV2(input)
  }

  if (isErrorResponseV1(input)) {
    return fromErrorResponseV1(input)
  }

  return fromErrorResponseUnknown(input)
}
