import type { Locale } from '@backmarket/http-api'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

const UNITS = ['year', 'month', 'day', 'hour', 'minute', 'second'] as const

export type Unit = (typeof UNITS)[number]
export type RelativeTimeFormatValues = Record<Unit, number>

export default function createNumberFormatter(
  locale: Locale,
  options: Intl.RelativeTimeFormatOptions = {},
) {
  return (values: RelativeTimeFormatValues) =>
    UNITS.map((unit) => {
      const value = values[unit]

      if (value) {
        const formatter = new Intl.RelativeTimeFormat(
          toBcp47Locale(locale),
          options,
        )

        const [, translatedValue, translatedUnit] = formatter.formatToParts(
          value,
          unit,
        )

        return `${translatedValue.value}${translatedUnit.value}`
      }

      return ''
    }).join(' ')
}
