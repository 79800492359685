import { useRequestEvent } from '#imports'

import { getMarketFromDomain } from '../helpers/getMarketFromDomain'
import { getMarketplace } from '../helpers/getMarketplace'
import { getRequestCountryCode } from '../helpers/getRequestCountryCode'

type MarketFeatures = Record<string, unknown>

/**
 * Retrieve the current marketplace.
 *
 * As Nuxt runtime configuration does not allow passing types, the market
 * features may be passed as a generic type argument whenever this composable
 * is executed. If not explicitly set, a generic type will be used instead.
 *
 * @example
 * type Features = {
 *   buybackEnabled: boolean,
 *   bannerEnabled: boolean,
 * }
 *
 * const marketplace = useMarketplace<Features>()
 *
 * @warning
 * For all usage as part of Nitro context, use `getMarketplace` instead.
 */
export function useMarketplace<
  Features extends MarketFeatures = MarketFeatures,
>() {
  const event = useRequestEvent()

  if (event) {
    const countryCode = getRequestCountryCode(event)

    return getMarketplace<Features>(countryCode)
  }

  const domain = window.location.origin
  const market = getMarketFromDomain(domain)

  return getMarketplace<Features>(market.countryCode)
}
