import { createHttpEndpoint } from '../../utils'
import type { BuybackOrderConversation } from '../models'

/**
 * Get buyback messages on a given order
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Staff/operation/get-bm-buyback-staff-orders-messages}
 */
export const getStaffOrderMessages =
  createHttpEndpoint<BuybackOrderConversation>({
    method: 'GET',
    operationId: 'getStaffOrderMessages',
    path: '/bm/buyback/v1/staff/orders/:id/messages',
  })
