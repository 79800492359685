const PRODUCTION_SUBDOMAIN = 'www'
const PRODUCTION_ACCOUNTS_SUBDOMAIN = 'accounts'
const PREPROD_ACCOUNTS_SUBDOMAIN = 'accounts.preprod'

export function useAuthOrigin() {
  const { hostname, origin } = window.location
  // if hostname contains `accounts`, we are already on the accounts subdomain, we do nothing.
  if (hostname.includes('accounts')) {
    return origin
  }

  const [subdomain] = hostname.split('.')

  if (subdomain === PRODUCTION_SUBDOMAIN) {
    return origin.replace(subdomain, PRODUCTION_ACCOUNTS_SUBDOMAIN)
  }

  return origin.replace(subdomain, PREPROD_ACCOUNTS_SUBDOMAIN)
}
