/* eslint-disable camelcase */
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import type { EventData } from '../../types'
import { formatPrice } from '../../utils/formatPrice'

export const productModel = ({
  brand,
  category,
  mainListing,
  model,
  name,
  price,
  currency,
  image,
  webUrl,
  mobileDeeplink,
  color,
  hasNewBattery,
  uuid,
  shippingDelay,
  specialOfferType,
  variant,
  listingId,
  mobilePlanOffers,
  mobilePlanOfferSelected,
  hasPremium,
}: EventData = {}) => {
  return removeEmptyValuesInObject({
    product_brand: brand,
    product_category: category,
    product_main_listing: mainListing,
    product_model: model,
    product_name: name,
    product_price: formatPrice({ amount: price, currency }),
    product_image: image,
    product_web_url: webUrl,
    product_mobile_deeplink: mobileDeeplink,
    product_color: color,
    product_hasNewBattery: hasNewBattery,
    product_id: uuid,
    product_price_currency: currency,
    shipping_free_delayinhours: `${shippingDelay}`,
    special_offer_type: specialOfferType,
    product_variant: `${variant}`,
    product_listingID: `${listingId}`,
    product_mobile_plan_eligible_offers:
      typeof mobilePlanOffers === 'string' ? mobilePlanOffers.split('-') : '',
    product_mobile_plan_selected_offer: mobilePlanOfferSelected,
    product_has_premium: hasPremium,
  })
}
