import type { Locale } from '@backmarket/http-api'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

export default function createCountryFormatter(locale: Locale) {
  return (countryCode: string) => {
    if (typeof Intl.DisplayNames !== 'function') {
      return countryCode
    }

    const regionNames = new Intl.DisplayNames([toBcp47Locale(locale)], {
      type: 'region',
    })

    return regionNames.of(countryCode) || countryCode
  }
}
