import type { ReadonlyDeep } from 'type-fest'

import { HttpApiError } from './HttpApiError'

/**
 * Name of HTTP headers that SHOULD be sent on every request.
 */
export enum HttpHeader {
  ACCEPT_LANGUAGE = 'accept-language',
  APP_VERSION = 'app-version',
  APP_PLATFORM = 'app-platform',
  CONTENT_TYPE = 'content-type',
  COOKIE = 'cookie',
  CSRF_TOKEN = 'x-csrftoken',
  ENCODING = 'accept-encoding',
  IDENTIFICATION_REQUEST_ID = 'x-request-id',
  IDENTIFICATION_VISITOR_ID = 'x-visitor-id',
  IDENTIFICATION_VISIT_ID = 'x-visit-id',
  MARKET_COUNTRY_CODE = 'x-country',
  MARKET_MARKETPLACE = 'x-marketplace',
  NGINX_IP = 'x-forwarded-for',
  USER_AGENT = 'user-agent',
  VERIFIED_BOT = 'x-bm-bopip-verified-bot',
  GEOIP_CF_COUNTRY = 'cf-ipcountry',
  GEOIP_CF_CITY = 'cf-ipcity',
  GEOIP_CF_LATITUDE = 'cf-iplatitude',
  GEOIP_CF_LONGITUDE = 'cf-iplongitude',
}

export enum HttpEvent {
  Attempt,
  Success,
  Fail,
}

export type HttpContext<T, B> = {
  endpointSettings: HttpEndpointSettings
  requestOptions: HttpRequestOptions<T, B>
  response?: Response
  error?: HttpApiError
  data?: T
}

/**
 * Parameters that are used to build the URL
 */
export type HttpRequestUrlParameters = Record<string, unknown>

export type DefaultHttpRequestBody =
  | RequestInit['body']
  | Record<string, unknown>
/**
 * Options to be passed as a parameter when calling a {@link HttpEndpoint}.
 */
export type HttpRequestOptions<T, B = DefaultHttpRequestBody> = {
  /**
   * Request body
   */
  body?: B

  /**
   * Base URL to use for this request.
   *
   * Overrides {@link HttpEndpointSettings.baseURL} if set.
   */
  baseURL?: string

  /**
   * Base URL to use by default. If the endpoint is defined with a
   * {@link HttpEndpointSettings baseURL}, the latter will take precedence over
   * `defaultBaseURL`.
   */
  defaultBaseURL?: string

  /**
   * Headers to be sent.
   *
   * These headers take precedence over {@link HttpEndpointSettings.headers}.
   */
  headers?: Record<string, unknown>

  /**
   * An optional callback function, that will be called:
   * - with event = HttpEvent.Attempt: before performing the request
   * - with event = HttpEvent.Error: if the request fail
   * - with event = HttpEvent.Success: if the request succeeds
   */
  onEvent?: (event: HttpEvent, context: HttpContext<T, B>) => void

  /**
   * Relative path of the endpoint.
   *
   * Overrides {@link HttpEndpointSettings} `path` if set.
   */
  path?: string

  /**
   * When an {@link HttpEndpoint endpoint} is defined with a {@link HttpEndpointSettings path}
   * that contains variables (ex: `/bm/product/v2/:id`), those variables must be
   * provided when calling the endpoint.
   *
   * @example For `/bm/product/v2/:id`:
   * { id: '1234' }
   * // => resolved URL: '/bm/product/v2/1243'
   */
  pathParams?: HttpRequestUrlParameters

  /**
   * Query parameters
   *
   * If query parameters are defined in both the endpoint and when calling the
   * endpoint, values will be merged. In case of conflict, values from the call
   * take precedence over the endpoint definition.
   *
   * @example For `/payment/payment_methods`
   * { listings: '123,456,789' }
   * // => resolved URL: '/payment/payment_methods?listings=123,456,789'
   */
  queryParams?: HttpRequestUrlParameters

  /**
   *
   * The AbortSignal interface represents a signal object that allows you to communicate
   * with a DOM request (such as a fetch request) and abort it if required via an AbortController object.
   */
  signal?: AbortSignal

  /**
   * Timeout in milliseconds for the request. When set to `0`, no timeout will be applied.
   * Overrides the one defined using `createHttpEndpoint()` to have more flexibility
   */
  timeout?: number
}

type HttpEndpointFunction<T, B> = (
  options?: HttpRequestOptions<T, B>,
) => Promise<T>

/**
 * A HTTP endpoint
 *
 * This is defined as a function, so the return type (<T>) is "baked" into the
 * endpoint, which makes typing much easier on the consumer side.
 *
 * The function will reject with a [HttpApiError](./HttpApiError.ts) instance,
 * when an error occurs or the endpoints returns a 4xx/5xx response.
 *
 * @typeParam T The type of the endpoint's response body
 */
export type HttpEndpoint<T, B = DefaultHttpRequestBody> = HttpEndpointFunction<
  T,
  B
> & {
  settings: HttpEndpointSettings
}

/**
 * Settings used to declare a {@link HttpEndpoint} instance.
 *
 * @example
 * {
 *   method: 'GET',
 *   path: '/payment/payment_method',
 *   operationId: 'getMethods'
 * }
 *
 * @example With default query parameters
 * {
 *   method: 'GET',
 *   path: '/bm/orders/client/:id',
 *   query: { page_size: 6 },
 *   operationId: 'getAfterSaleExperienceOrderlines'
 * }
 *
 * @example With a base URL
 * {
 *   method: 'GET',
 *   baseUrl: process.env.HELP_CENTER_SERVICE_URL,
 *   path: '/help-center/api/v1/auth/orders',
 *   operationId: 'getHelpCenterCustomerOrders'
 * }
 */
export type HttpEndpointSettings = {
  /**
   * When set, this will be used as base URL for every request.
   */
  baseURL?: string

  /**
   * The HTTP method (aka "verb") of the endpoint
   * @example 'GET'
   */
  method: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT'

  /**
   * A unique identifier, that is used to reference this endpoint in external
   * systems (ex: DataDog logs). The value must be identical to the one defined
   * in the OpenAPI specifications of the endpoint.
   *
   * Ideally, the value should be in the following format:
   *
   * ```txt
   * method (lowercase, ex 'get')
   * + <api name> (PascalCased, ex 'Payment')
   * + <endpoint name> (PascalCased, ex 'Methods')
   * ```
   *
   * If this is not the case, consider updating the API specs to conform to this
   * format.
   *
   * @example 'getMethods'
   */
  operationId: string

  /**
   * Relative path of the endpoint
   *
   * The path may contain variables (prefixed by `:`). Those variables will need
   * to be addressed by the caller of the endpoint, by using the
   * {@link HttpRequestOptions}'s `pathParams` object.
   *
   * @example '/payment/payment_methods'
   * @example '/bm/product/v2/:id'
   */
  path: string

  /**
   * When set, this will be added as query to every request. The resulting
   * values will be the union of this map, and the `query` option being passed
   * when calling the endpoint (if any)
   */
  defaultQueryParams?: HttpRequestUrlParameters

  /**
   * Headers to be sent for all requests.
   *
   * They can still be overriden when actually firing the HTTP request using
   * {@link HttpRequestOptions.headers}.
   */
  headers?: Record<string, string>

  /**
   * When set to `true`, request body will be transformed with snake_cased keys.
   * (defaults to false)
   */
  transformRequestToSnakeCase?: boolean

  /**
   * When set to `true`, response body will be transformed with camelCased keys.
   * (defaults to false)
   */
  transformResponseToCamelCase?: boolean

  /**
   * Timeout in milliseconds for the request. When set to `0`, no timeout will be applied.
   * (defaults to 20_000 - 20 seconds)
   */
  timeout?: number

  /**
   * The credentials read-only property of the Request interface indicates whether the user agent
   * should send or receive cookies from the other domain in the case of cross-origin requests.
   */
  credentials?: 'omit' | 'same-origin' | 'include'
}

export type HttpUnknownResponsePayloadRecord = Record<string, unknown>
export type HttpUnknownResponsePayloadArray =
  | Array<unknown>
  | Array<Record<string, unknown>>
  | ReadonlyArray<Record<string, unknown>>

/**
 * Default type for HTTP response payloads.
 *
 * Note that we can restrict this to plain JavaScript objects and arrays
 * because we only receive JSON payloads from our API endpoints.
 */
export type HttpUnknownResponsePayload =
  | HttpUnknownResponsePayloadRecord
  | HttpUnknownResponsePayloadArray
  | unknown

export type HttpResponsePayload<T> = ReadonlyDeep<T>
