import { decamelizeKeys } from '@backmarket/utils/object/decamelizeKeys'

import type { ContextData, UserContext } from '../types'

import { USER_GROUP_NAME, USER_GROUP_VALUES } from './constants'

import { setUserId, setUserProperties } from '.'

function getAmplitudeGroupValue(isStaff: boolean, merchantId?: number | null) {
  if (isStaff) {
    return USER_GROUP_VALUES.LOGGED_STAFF
  }

  if (merchantId !== null) {
    return USER_GROUP_VALUES.LOGGED_MERCHANT
  }

  return USER_GROUP_VALUES.LOGGED_CUSTOMER
}

export function setUserContext(userContext: UserContext) {
  const groupValue = getAmplitudeGroupValue(
    userContext.isStaff,
    userContext.merchantId,
  )

  setUserId(userContext.userId.toString())

  const decamelizedUserProperties = decamelizeKeys({
    [USER_GROUP_NAME]: groupValue,
    lastOrderDate: userContext.lastOrderDate,
    lifetimeRevenue: userContext.lifetimeRevenue,
    numberOfOrders: userContext.numberOfOrders,
  }) as Record<string, unknown>

  setUserProperties(decamelizedUserProperties)
}

export function setContext({ abtest, flags, ...rest }: ContextData) {
  setUserProperties({
    abtest,
    flags,
    ...decamelizeKeys(rest),
  })
}
