import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

import { useSessionId } from '@backmarket/nuxt-module-identification/useSessionId'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { createLogger } from './server/utils/createLogger'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig().public.logger

  const { market } = useMarketplace()
  const attributes = {
    countryCode: market.countryCode,
    marketplaceCode: market.marketplace,
    bm: {
      session_id: useSessionId(),
      visitor_id: useVisitorId(),
    },
  }

  window.logger = createLogger({ attributes })

  if (config.wrapConsole) {
    window.logger.wrapConsole({ logToNativeConsole: true })
  }
})
