export type MetaTypeKeys = keyof typeof META_TAGS

export const NOINDEX_META_TAG = {
  hid: 'robots',
  name: 'robots',
  content: 'noindex',
}

export const DESCRIPTION_META_TAGS = [
  'description',
  'og:description',
  'twitter:description',
] as const
export const IMAGE_META_TAGS = ['og:image', 'twitter:image', 'logo'] as const
export const TITLE_META_TAGS = ['og:title', 'twitter:title'] as const
export const URL_META_TAGS = ['og:url', 'twitter:url'] as const

// List of tags types and their associated meta tag
export const META_TAGS = {
  description: DESCRIPTION_META_TAGS,
  title: TITLE_META_TAGS,
  image: IMAGE_META_TAGS,
  url: URL_META_TAGS,
}

// Meta tags added on every pages
export const BASE_META = [
  {
    hid: 'og:type',
    property: 'og:type',
    content: 'website',
  },
  {
    hid: 'twitter:site',
    name: 'twitter:site',
    content: '@back_market',
  },
  {
    hid: 'twitter:card',
    name: 'twitter:card',
    content: 'summary_large_image',
  },
]

// The block bellow has not been migrated //
export const SCHEMA_LOGO = '/img/logo-bm-schema.png'

export const SCHEMA_SEARCH_NAME = 'q'

export const SCHEMA_PRODUCT_IMAGE = '/img/relovethepreloved.png'

export const SCHEMA_BEST_RATING = 5

export const SCHEMA_WORST_RATING = 1

export const SCHEMA_ITEM_CONDITION = {
  REFURBISHED: 'http://schema.org/RefurbishedCondition',
  NEW: 'http://schema.org/NewCondition',
}
