import {
  toPublicPath,
  useHeadSafe,
  useRequestURL,
  useRuntimeConfig,
} from '#imports'

import type { MetaTypeKeys } from '../utils/seo/constants'
import { getMetas } from '../utils/seo/meta'

type UseHeadSafeParams = Omit<
  Parameters<typeof useHeadSafe>[0],
  'meta' | 'title' | 'titleTemplate'
>

type UseBmHeadInput = UseHeadSafeParams &
  Partial<Record<MetaTypeKeys, string>> & {
    meta?: Record<string, string>
  }

export const BASE_META = {
  'og:type': 'website',
  'twitter:site': '@back_market',
  'twitter:card': 'summary_large_image',
}

export const DEFAULT_META_IMAGE = '/img/relovethepreloved.png'

export function useBackmarketHead(input: UseBmHeadInput) {
  const {
    public: { COMPANY_NAME },
  } = useRuntimeConfig()

  const {
    title = COMPANY_NAME,
    url = useRequestURL().href,
    image = DEFAULT_META_IMAGE,
    description,
    meta,
    ...rest
  } = input

  useHeadSafe({
    ...rest,
    meta: getMetas({
      ...BASE_META,
      title,
      image: toPublicPath(image),
      url,
      ...(description && { description }),
      ...meta,
    }),
    title,
    titleTemplate: `%s | ${COMPANY_NAME}`,
  })
}
