export default {
  title: {
    id: 'expired_password_reset_title',
    defaultMessage: 'Oops!',
  },
  text: {
    id: 'expired_password_reset_text',
    defaultMessage:
      'Your password reset request has expired. Please try again.',
  },
  cta: {
    id: 'expired_password_reset_cta',
    defaultMessage: 'Retry!',
  },
}
