import type { RouteRecordRaw } from 'vue-router'

import { HOME } from './route-names'

export default (): RouteRecordRaw[] => {
  return [
    {
      component: () => import('./Home.vue'),
      name: HOME,
      path: '',
      meta: {
        disableClientSideNavigation: () => false,
      },
    },
  ]
}
