import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

/**
 * Enables the XState Visualizer, which can be used to show
 * state machine diagrams from machine definitions.
 *
 * Note: to use this the `devTools: true` property has
 * to be passed into the interpret options.
 */
export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()

  if (config.public.DEV_XSTATE_INSPECTOR_ENABLED) {
    // Make sure that the `@xstate/inspect` is properly excluded from the main
    // bundle, and only downloaded when necessary. We have to rely on code
    // splitting because treeshaking will not exclude the dependency.
    const { inspect } = await import('@xstate/inspect')
    inspect({ iframe: false })
  }
})
