import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import RouterLinkComponent from '@backmarket/nuxt-module-navigation/RouterLinkComponent.vue'
import { addTrailingSlash } from '@backmarket/utils/url/addTrailingSlash'
import { removeLeadingSlash } from '@backmarket/utils/url/removeLeadingSlash'
import { type Configuration, createRevolvePlugin } from '@ds/plugin'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { currentLocale } = useI18n()

  const illustrationOptimizer = (
    path: Parameters<Configuration['illustrationOptimizer']>[0],
    width: Parameters<Configuration['illustrationOptimizer']>[1],
    { format, quality }: Parameters<Configuration['illustrationOptimizer']>[2],
  ): ReturnType<Configuration['illustrationOptimizer']> => {
    const href = config.app.cdnURL
      ? new URL(removeLeadingSlash(path), addTrailingSlash(config.app.cdnURL))
          .href
      : path

    if (config.public?.IMAGE_OPTIMIZATION_PROVIDER === 'cloudflare') {
      // Cloudflare images does not fully support .svg and .gif files as input.
      if (path.endsWith('.svg') || path.endsWith('.gif')) {
        return href
      }

      /**
       * Targeting /cdn-cgi/image/ will use Cloudflare image optimization.
       * Width, quality & format comes from the design-system, the later ones being 75 & "auto" by default.
       * Automatic format will convert images to webp if the browser supports it.
       *
       * @see https://developers.cloudflare.com/images/transform-images/transform-via-url/
       */
      const params = `format=${format},quality=${quality},width=${width}`
      const cloudflareOptimizedUrl = `/cdn-cgi/image/${encodeURIComponent(params)}/${removeLeadingSlash(href)}`

      return cloudflareOptimizedUrl
    }

    return href
  }

  const dsVuePlugin = createRevolvePlugin({
    RouterLinkComponent,
    illustrationOptimizer,
    locale: ref(currentLocale as string),
  })

  nuxtApp.vueApp.use(dsVuePlugin)
})
