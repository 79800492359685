import { useAsyncData } from '#imports'
import { reactive } from 'vue'

import type { KeysOf } from '#app/composables/asyncData'
import {
  type DefaultHttpRequestBody,
  type HttpEndpoint,
  type HttpError,
} from '@backmarket/http-api'

import type { HttpFetchRequestOptions } from '../types/HttpFetchRequestOptions'
import type { UseHttpFetchOptions } from '../types/UseHttpFetchOptions'
import type { UseHttpFetchResult } from '../types/UseHttpFetchResult'
import { $httpFetch } from '../utils/$httpFetch'
import { generateHttpRequestKey } from '../utils/generateHttpRequestKey'

/**
 * This composable provides a convenient wrapper around `useAsyncData` and
 * `$httpFetch`.
 *
 * @remarks It replaces {@link https://nuxt.com/docs/api/composables/use-fetch useFetch},
 * when using `@backmarket/http-api`.
 *
 * @param endpoint The {@link HttpEndpoint endpoint} to call. See `http-api`
 * package for all possible endpoints, and/or how to declare a new endpoint.
 * @param options Additional request options, combined with async data options
 *
 * @see {@link https://nuxt.com/docs/api/composables/use-async-data useAsyncData}
 * @see {@link ../utils/$httpFetch.ts $httpFetch}
 *
 * @example
 * import { paymentAPI } from '@backmarket/http-api'
 *
 * const { data, pending, error } = await useHttpFetch(paymentAPI.getMethods, {
 *   query: { country_code: 'US' },
 * })
 */
export function useHttpFetch<
  ResT,
  ErrorT = HttpError,
  BodyT = DefaultHttpRequestBody,
  DataT = ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null,
>(
  endpoint: HttpEndpoint<ResT, BodyT>,
  options?: UseHttpFetchOptions<ResT, DataT, BodyT, PickKeys, DefaultT>,
): UseHttpFetchResult<ResT, ErrorT, DataT, PickKeys, DefaultT>
export function useHttpFetch<
  ResT,
  ErrorT = HttpError,
  BodyT = DefaultHttpRequestBody,
  DataT = ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = DataT,
>(
  endpoint: HttpEndpoint<ResT, BodyT>,
  options: UseHttpFetchOptions<ResT, DataT, BodyT, PickKeys, DefaultT> = {},
): UseHttpFetchResult<ResT, ErrorT, DataT, PickKeys, DefaultT> {
  const key = generateHttpRequestKey(endpoint, options)

  // Creating a reactive object here allows to pass Ref instances within any
  // option (query params, body, headers, etc).
  const reactiveOptions = reactive(options)

  const asyncDataPromise = useAsyncData<
    ResT,
    ErrorT,
    DataT,
    PickKeys,
    DefaultT
  >(
    key,
    () =>
      $httpFetch(
        endpoint,
        reactiveOptions as HttpFetchRequestOptions<ResT, BodyT>,
      ),
    {
      ...options,

      // Add reactive options to the watch list automatically, unless `watch` is
      // set to `false`.
      watch:
        options.watch === false
          ? []
          : [reactiveOptions, ...(options.watch || [])],
    },
  )

  // @ts-expect-error - Dunno what's wrong.
  return asyncDataPromise
}
