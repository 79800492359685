<template>
  <RevContainer>
    <div class="grid-standard mb-32 text-center">
      <div class="md:col-span-4 lg:col-span-8 lg:col-start-3">
        <h1 class="heading-1 my-16">
          {{ i18n(translations.title) }}
        </h1>
        <p class="body-1">
          {{ i18n(translations.text) }}
        </p>
        <RevButton
          class="mx-auto mb-0 mt-32"
          data-test="expired-password-reset-cta"
          :to="{ name: ROUTES.AUTH.RESET_PASSWORD }"
          variant="primary"
        >
          {{ i18n(translations.cta) }}
        </RevButton>
      </div>
    </div>
  </RevContainer>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'

import translations from '~/scopes/auth/pages/SetNewPasswordExpired/SetNewPasswordExpired.translations'
import { ROUTES } from '~/scopes/auth/route-names'

const i18n = useI18n()
</script>
