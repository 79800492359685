import { Currency, Locale, type Price } from '@backmarket/http-api'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

function isPriceModel(value: Price | string | number): value is Price {
  return typeof value !== 'string' && typeof value !== 'number'
}

export type CreatePriceFormatterOptions = Intl.NumberFormatOptions & {
  currency?: Currency
  symbol?: boolean
}

export default function createPriceFormatter(
  locale: Locale,
  defaultCurrency: Currency,
) {
  return (
    amountOrPrice?: Price | string | number,
    options: CreatePriceFormatterOptions = {},
  ) => {
    if (!amountOrPrice || JSON.stringify(amountOrPrice) === '{}') {
      return ''
    }

    const safeCurrency = options.currency || defaultCurrency

    /**
     * @FIXME - After Pastrami migration
     * We should always receive a price.
     * We should try to deprecate support for argument of type string and number.
     */
    const price = isPriceModel(amountOrPrice)
      ? amountOrPrice
      : {
          amount: amountOrPrice.toString(),
          currency: safeCurrency,
        }

    const { symbol = true, ...intlNumberFormatOptions } = options

    const optionsWithOverrides = {
      currency: price.currency,
      style: 'currency',
      ...intlNumberFormatOptions,
    }

    const numberFormat = Intl.NumberFormat(
      toBcp47Locale(locale),
      optionsWithOverrides,
    )

    const priceWithCurrencySymbol = numberFormat.format(
      parseFloat(price.amount),
    )

    if (symbol) {
      // Native implementation behavior is to display A$XX only for en-us locale
      // but product requirements for backmarket.com.au is to use A$XX for any locale
      if (
        optionsWithOverrides.currency === Currency.AUD &&
        locale === Locale.en_AU
      ) {
        return `A${priceWithCurrencySymbol}`
      }

      // Replace the yen character to always have the same
      // Safari Intl.NumberFormat format differently from other browsers (yen symbol is not the same unicode) causing hydration error
      if (
        optionsWithOverrides.currency === Currency.JPY &&
        locale === toBcp47Locale(Locale.ja_JP)
      ) {
        const priceFormatted = numberFormat
          .formatToParts(parseFloat(price.amount))
          .map(({ type, value }) => {
            switch (type) {
              case 'currency':
                return '￥'
              default:
                return value
            }
          })
          .reduce((string, part) => string + part)

        return priceFormatted
      }

      return priceWithCurrencySymbol
    }

    const removeCurrencySymbolRegex = /[^\d.,-\s]+/g

    return priceWithCurrencySymbol.replace(removeCurrencySymbolRegex, '').trim()
  }
}
