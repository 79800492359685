import { useRequestEvent } from '#imports'

/**
 * Retrieve the instance of front-logger.
 *
 * @example
 * const logger = useLogger()
 *
 * logger.info('My info log message', payload)
 * logger.debug('My debug log message', payload)
 * logger.error('My error log message', payload)
 */
export function useLogger() {
  const event = useRequestEvent()

  if (event) {
    return event.context.logger
  }

  return window.logger
}
