/**
 * Normalize the given input so it's always an array.
 *
 * @example
 * toArray('A') // ['A']
 *
 * @example
 * toArray(['A']) // ['A']
 *
 * @example
 * toArray(['A', 'B']) // ['A', 'B']
 */
export function toArray<T>(input: T | Array<T>): Array<T> {
  return Array.isArray(input) ? input : [input]
}
