import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { PASTRAMI_CUSTOMER_CONVERSATION } from '../customer-requests/routes'

import { CUSTOMER_CONVERSATION } from './route-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY } =
    useRuntimeConfig().public
  const isCustomerConversationEnabled = (
    FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY as string
  )?.includes(market.countryCode)

  return [
    {
      path: 'help-request/:resolutionId',
      name: CUSTOMER_CONVERSATION.RESOLUTION,
      ...(isCustomerConversationEnabled
        ? {
            component: () => import('./pages/Conversation/Conversation.vue'),
          }
        : {
            redirect: { name: PASTRAMI_CUSTOMER_CONVERSATION.RESOLUTION },
          }),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: () => false,
        layout: false,
      },
    },
    {
      path: 'help-request/care-folder/:careFolderId',
      name: CUSTOMER_CONVERSATION.CARE_FOLDER,
      ...(isCustomerConversationEnabled
        ? {
            component: () => import('./pages/Conversation/Conversation.vue'),
          }
        : {
            redirect: { name: PASTRAMI_CUSTOMER_CONVERSATION.CAREFOLDER },
          }),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: () => false,
        layout: false,
      },
    },
  ]
}
