import { decamelizeKeys } from '@backmarket/utils/object/decamelizeKeys'
import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import type {
  CarouselData,
  EventData,
  ModalData,
  PageViewData,
  PartnershipBannerImpression,
  TrackPageViewParams,
} from '../types'

import {
  amplitudeFavoriteTrackingModel,
  amplitudeFavoriteTrackingModelCollection,
} from './models/favorites'
import { productModel, productModelCollection } from './models/product'
import { globalRatingModel, reviewModel } from './models/review'

import { sendEvent } from '.'

export function trackClick(payload: Record<string, unknown>) {
  sendEvent('clicked', decamelizeKeys(payload))
}

export function trackChange(payload: Record<string, unknown>) {
  sendEvent('changed', decamelizeKeys(payload))
}

export function trackPageView({ to, from }: TrackPageViewParams) {
  if (!process.client) {
    return
  }

  const isNewInternalPage = from.fullPath !== to.fullPath

  const referrer =
    from.name && isNewInternalPage
      ? {
          page_type: from.name,
          params: from.params,
        }
      : { url: document.referrer }

  const payload = {
    current_page: {
      page_type: to.name,
      params: to.params,
    },
    referrer,
  }

  sendEvent('page_viewed', payload)
}

export function trackScreenView(payload: Record<string, unknown>) {
  sendEvent('screen_viewed', decamelizeKeys(payload))
}

export function trackWebPerformanceAll(
  data: PageViewData & { [metric: string]: unknown },
) {
  sendEvent('web_performances_all', data)
}

export function trackModal({ name, status }: ModalData) {
  if (status === 'opened') {
    sendEvent('modal_viewed', { name })
  }
}

export function trackFormSubmit(payload: EventData) {
  sendEvent('info_submitted', decamelizeKeys(payload))
}

export function trackVideoImpression(payload: EventData) {
  sendEvent('video_impression', decamelizeKeys(payload))
}

export function trackVideosBlockImpression(payload: EventData) {
  sendEvent('videos_block_impression', decamelizeKeys(payload))
}

export function trackHoverOver(payload: EventData) {
  sendEvent('hover_over', decamelizeKeys(payload))
}

export const trackReviewImpression = ({ pageType, ...context }: EventData) => {
  const productInfo = productModel(context)
  const reviewInfo = reviewModel(context)

  const payload = { ...productInfo, ...reviewInfo, page_type: pageType }

  sendEvent('review_impression', payload)
}

export const trackReviewBlockImpression = ({
  pageType,
  ...context
}: EventData) => {
  const productInfo = productModel(context)
  const ratingInfo = globalRatingModel(context)

  const payload = { ...productInfo, ...ratingInfo, page_type: pageType }

  sendEvent('review_block_impression', payload)
}

export function trackProductImpressionBatched(products: EventData[]) {
  sendEvent('product_impression', {
    products: productModelCollection(products),
  })
}

export function trackProductImpression(product: EventData) {
  sendEvent('product_impression', productModel(product))
}

export function trackAddToCart(payload: EventData) {
  sendEvent('add_to_cart', productModel(payload))
}

export function trackAddToCartBatch({ products }: { products: EventData[] }) {
  sendEvent('add_to_cart', {
    products: productModelCollection(products),
  })
}

export function trackProductClick(payload: EventData) {
  sendEvent('product_clicked', productModel(payload))
}

export function trackLandingBannerImpression({
  name,
  creative,
  position,
}: EventData) {
  sendEvent('promotion_impression', {
    name,
    creative,
    position,
  })
}

export function trackLandingBannerClick({
  name,
  creative,
  position,
}: EventData) {
  sendEvent('promotion_clicked', {
    name,
    creative,
    position,
  })
}

export function trackErrors(payload: EventData) {
  sendEvent('errors', payload)
}

export function trackRecyclingProduct(payload: EventData) {
  sendEvent('recycling_product', payload)
}

export function trackRecyclingOrder(payload: EventData) {
  sendEvent('recycling_order', payload)
}

export function trackCarouselClick(payload: EventData) {
  sendEvent('promotion_clicked', decamelizeKeys(payload))
}

export function trackCarouselImpression(payload: CarouselData) {
  const { technicalName, currentSlide, blockPosition, value, zone, creatives } =
    payload

  const data = {
    name: creatives?.[currentSlide]?.creative ?? '',
    value,
    zone,
    blockPosition,
    position: currentSlide + 1,
    technicalName,
  }

  if (currentSlide > 0) {
    sendEvent('carousel_impression', decamelizeKeys(data))
  }
}

export const trackPaginationClicked = (payload: EventData) => {
  sendEvent('pagination_clicked', decamelizeKeys(payload))
}

export function trackProduct(payload: EventData) {
  sendEvent('product', productModel(payload))
}

export function trackToastView(payload: EventData) {
  sendEvent('toast_viewed', decamelizeKeys(payload))
}

export function trackProductBlockImpression(payload: EventData) {
  sendEvent('pp_block_impression', removeEmptyValuesInObject(payload))
}

export function trackAddToMyFavorites(payload: EventData) {
  sendEvent('add_to_my_favorites', amplitudeFavoriteTrackingModel(payload))
}

export function trackFavoritesPage({
  pageType,
  favorites,
}: {
  pageType: string
  favorites: EventData[]
}) {
  const data = {
    pageType,
    products: amplitudeFavoriteTrackingModelCollection(favorites),
  }

  sendEvent('favorites_page_viewed', decamelizeKeys(data))
}

export function trackFunnel({
  products,
  pageType,
  swap,
}: {
  products: EventData[]
  pageType: string
  swap: boolean
}) {
  const data = {
    products: productModelCollection(products),
    page_type: pageType,
    swap,
  }

  sendEvent('checkout_page_viewed', data)
}

export function trackPaymentResultSuccess({
  payment,
  products,
}: {
  payment: EventData
  products: EventData[]
}) {
  let productRevenue = 0
  let quantity = 0

  products.forEach((product) => {
    productRevenue += Number(product.price) * Number(product.quantity)
    quantity += Number(product.quantity)

    sendEvent(
      'purchase_product',
      decamelizeKeys({
        order_id: payment.orderId,
        ...productModel(product),
      }),
    )
  })

  sendEvent('purchase_order', {
    shipping_revenue: payment.shipping,
    swap_eligible: payment.swap,
    sponsorship: payment.code_parrain,
    commission: payment.commission,
    '10Pct_FEE': payment['10Pct_FEE'],
    months_since_last_purchase: payment.monthsSinceLastPurchase,
    order_id: payment.orderId,
    product_revenue: productRevenue,
    product_quantity: quantity,
    payment_method: payment.paymentMethod,
    // Calculation of the product prices only (no fees etc.)
    $revenue: payment.revenue,
    coupon: payment.discount,
  })
}

export function trackPaymentResultFailure(payload: EventData) {
  sendEvent('paymentFailure', decamelizeKeys(payload))
}

export function trackPostPurchasePayment(payload: EventData) {
  sendEvent('postPurchaseProduct', decamelizeKeys(payload))
}

export function trackPromptNotificationImpression(payload: EventData) {
  sendEvent('promptNotificationImpression', decamelizeKeys(payload))
}

export function trackPartnershipBannerImpression(
  payload: PartnershipBannerImpression,
) {
  sendEvent('bm_partner_impression', decamelizeKeys(payload))
}

export function trackCheckpointsImpression(payload: EventData) {
  sendEvent('block_impression', decamelizeKeys(payload))
}

export function trackProduct360Impression(payload: EventData) {
  sendEvent('block_impression', decamelizeKeys(payload))
}

export function trackSellItem(payload: EventData) {
  sendEvent('sell_item_clicked', decamelizeKeys(payload))
}

export function trackHelpRequestCreated(payload: EventData) {
  sendEvent('help_request_created', decamelizeKeys(payload))
}
