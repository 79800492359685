import { defineNuxtPlugin, useCookie } from '#imports'

import { BRAZE_CONSENT_COOKIE } from '../braze/constants'
import { useLegalStore } from '../composables/useLegalStore'
import { COOKIES } from '../constants'
import type { CustomizableCookie, UserCookies } from '../types'

export default defineNuxtPlugin(() => {
  const legalStore = useLegalStore()

  const cookiesFromBrowser = Object.entries(COOKIES)
    .filter(([, cookieKey]) => {
      const storageCookie = useCookie<boolean>(cookieKey)

      return typeof storageCookie.value === 'boolean'
    })
    .reduce(
      (acc, [cookieType, cookieKey]) =>
        ({
          ...acc,
          [cookieType as CustomizableCookie]:
            useCookie<boolean>(cookieKey).value,
        }) as Record<CustomizableCookie, boolean>,
      {} as UserCookies,
    )

  // Following new Google user consent policy, Braze needs to send user's consent to Google
  // see https://www.google.com/about/company/user-consent-policy/,
  // see also https://www.braze.com/docs/partners/canvas_steps/google_audience_sync#collecting-consent-for-eea-and-uk-end-users)
  const brazeCookie = useCookie<boolean>(BRAZE_CONSENT_COOKIE)

  // the value of the cookie does not matter, we're only checking if it has been set already
  const isBrazeConsentUpToDate = brazeCookie.value !== undefined

  if (isBrazeConsentUpToDate) {
    legalStore.load({ cookiesFromBrowser })

    return
  }

  // In order to update users' consent we need to show them the cookie banner again
  // and update the cookies if they already accepted either advertising or userExperience cookies
  function requiresNewValidation({
    cookieKey,
    hasBeenAccepted,
  }: {
    cookieKey: CustomizableCookie
    hasBeenAccepted: boolean
  }) {
    const brazeRequiredCookies: CustomizableCookie[] = [
      'advertising',
      'userExperience',
    ]
    const isRequiredByBraze = brazeRequiredCookies.includes(cookieKey)

    return isRequiredByBraze && hasBeenAccepted
  }

  const filteredLegalCookies = Object.entries(cookiesFromBrowser)
    .filter(([key, value]) => {
      const cookieKey = key as CustomizableCookie

      return !requiresNewValidation({ cookieKey, hasBeenAccepted: value })
    })
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      }
    }, {} as UserCookies)

  legalStore.load({ cookiesFromBrowser: filteredLegalCookies })
})
