import { createHttpEndpoint } from '../../utils'

/**
 * Create a preference user cookie
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-legacy-api/definition#operation/post-bm-preferences-user-cookies}
 */
export const postPreferencesUserCookies = createHttpEndpoint({
  method: 'POST',
  operationId: 'post-bm-preferences-user-cookies',
  path: '/bm/preferences/user/cookies',
})
