/**
 * Transform a list of key-value pairs into an object.
 *
 * This is a basic replacement for `Object.fromEntries`, which we don't want to use
 * currently, because of its insufficient browser support.
 * @see {@link https://caniuse.com/mdn-javascript_builtins_object_fromentries}
 *
 * @param entries An iterable object of [key, value] pairs
 * @returns An object indexed by keys, containing values
 */
export function fromEntries<Value>(
  entries: Iterable<[PropertyKey, Value]>,
): Record<PropertyKey, Value> {
  const result: Record<PropertyKey, Value> = {}

  for (const [key, value] of entries) {
    result[key] = value
  }

  return result
}
