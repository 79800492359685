/**
 * Status of a market
 *
 * @see {@link https://github.com/BackMarket/api-models/blob/main/models/Market.yaml OpenAPI specification}
 */
export enum MarketStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  PRE_LAUNCH = 'PRE_LAUNCH',
}
