import { defineNuxtPlugin, useRoute } from '#imports'

import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'

import { ROUTES } from '~/scopes/auth/route-names'

import { updateUserContext } from '../utils/tracking/updateUserContext'

/**
 * Fetch user information when the application loads.
 */
export default defineNuxtPlugin((nuxtApp) => {
  // We only want to fetch user information client-side. If we were to fetch
  // them server-side, this would conflict with our caching strategy, and
  // user information (such as their avatar) would be cached for everbody.

  const authStore = useAuthStore()
  const userStore = useUserStore()
  const route = useRoute()

  const needToFetchUser = () =>
    authStore.fetched === false &&
    process.env.VITEST !== 'true' &&
    // Login page is a special case,
    // it should fetch the user by itself after the login session is started
    route.name !== ROUTES.AUTH.LOGIN

  const prefetchPromise =
    nuxtApp.isHydrating && needToFetchUser()
      ? userStore.prefetchUser()
      : undefined

  // we use the nuxt app:suspense:resolve hook to launch the userFetch after hydration
  nuxtApp.hook('app:suspense:resolve', async () => {
    if (needToFetchUser()) {
      await userStore.fetchUser(prefetchPromise)
    }

    void updateUserContext()
  })
})
