import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import type { EventData } from '../../types'
import { formatPrice } from '../../utils/formatPrice'

export function brazeFavoriteTrackingModel({
  brand,
  category,
  color,
  currency,
  hasNewBattery,
  mobileDeeplink,
  model,
  name,
  price,
  list = 'my_favorites',
  productUuid,
  webUrl,
  listingId,
  variant,
  imageUrl,
}: EventData) {
  const productVariant = variant ? `${variant}` : ''
  const productListingId = listingId ? `${listingId}` : ''
  const productPrice = currency
    ? formatPrice({ amount: price, currency })
    : price

  return removeEmptyValuesInObject({
    product_brand: brand,
    product_category: category,
    product_color: color,
    product_hasNewBattery: hasNewBattery,
    product_id: productUuid,
    product_list: list,
    product_mobile_deeplink: mobileDeeplink,
    product_model: model,
    product_name: name,
    product_price: productPrice,
    product_price_amount: price,
    product_price_currency: currency,
    product_web_url: webUrl,
    product_listingID: productListingId,
    product_variant: productVariant,
    product_image: imageUrl,
  })
}
