import { parse } from '../query-string/parse'

export const urlHashToObject = (hash: string) => {
  return Object.entries(parse(hash)).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value) ? value : [value],
    }),
    {},
  )
}
