export const CHECKOUT = {
  ADDRESS_CONFIRMATION: 'checkout_address_confirmation',
  AUTHENTICATION: 'checkout_authentication',
  BILLING_ADDRESS: 'checkout_billing_address',
  CART: 'checkout_cart',
  INSURANCE: 'checkout_insurance',
  PAYMENT_RESULT: 'checkout_payment_result',
  PAYMENT: 'checkout_payment',
  POST_AUTHENTICATION: 'checkout_post_authentication',
  SELF: 'checkout',
  SHIPPING_ADDRESS: 'checkout_shipping_address',
  SERVICES: {
    BOUYGUES: {
      SIGN_UP: 'checkout_services_bouygues_telecom_sign_up',
      AUTH: 'checkout_services_bouygues_telecom_auth',
      PHONELINE_SETUP: 'checkout_services_bouygues_telecom_phoneline_setup',
      NOT_ELIGIBLE: 'checkout_services_bouygues_telecom_not_eligible',
    },
  },
}

export const CHECKOUT_STEP_IDS = {
  [CHECKOUT.CART]: 1,
  [CHECKOUT.ADDRESS_CONFIRMATION]: 4,
  [CHECKOUT.SHIPPING_ADDRESS]: 4,
  [CHECKOUT.BILLING_ADDRESS]: 5,
  [CHECKOUT.PAYMENT]: 6,
}
