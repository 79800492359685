import type { Market } from '@backmarket/http-api'

import { DEFAULT_MARKET, MARKETS } from '../config'

/**
 * Retrieve the market associated to the given domain. If no market matches,
 * the default market is returned instead.
 *
 * @example
 * const domain = window.location.origin
 * const market = getMarketFromDomain(domain)
 *
 * @warning
 * For all usage as part of Nuxt context, use `useMarketplace` instead.
 */
export function getMarketFromDomain(domain: string): Market {
  // Remove port from the given domain as we don't care about it.
  const normalizedDomain = domain.replace(/:[0-9]+$/, '')

  const market = Object.values(MARKETS).find((m) => {
    return normalizedDomain.endsWith(m.domain)
  })

  return market ?? DEFAULT_MARKET
}
