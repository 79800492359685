import { defineNuxtRouteMiddleware } from '#imports'

import { designSystemVersion } from '@backmarket/design-system/metadata'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

/**
 * Augment the logger context with the DS version.
 * It is then injected into the `ds` property of each and every emitted logs.
 */
export default defineNuxtRouteMiddleware(() => {
  useLogger().appendGlobalContext({
    designSystem: designSystemVersion,
  })
})
