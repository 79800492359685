import { useRequestHeaders } from '#imports'

import { type ExperimentName } from '../config'
import { getDefaultExperiments, getExperimentsFromHeaders } from '../utils'

declare global {
  interface Window {
    __EXPERIMENTS__?: Record<ExperimentName, string>
  }
}

/**
 * Retrieve experiments.
 *
 * @example
 * const experiments = useExperiments()
 */
export function useExperiments(): Record<ExperimentName, string> {
  const defaultExperiments = getDefaultExperiments()

  if (process.server) {
    const headers = useRequestHeaders()
    const experiments = getExperimentsFromHeaders(headers)

    return {
      ...defaultExperiments,
      ...experiments,
    }
  }

  return {
    ...defaultExperiments,
    // By using underscore dangles, we follow the same structure as Nuxt.
    // eslint-disable-next-line no-underscore-dangle
    ...window.__EXPERIMENTS__,
  }
}
