<template>
  <RevContainer class="py-24 text-center">
    <div class="mb-32">
      <p class="punchline mb-16">
        {{ error.statusCode }}
      </p>
      <h1 class="punchline">
        {{ i18n(translations.title) }}
      </h1>
    </div>
    <p class="body-1-bold">
      {{ i18n(translations.subtitle) }}
    </p>
    <p class="body-1">
      {{ i18n(translations.beCool) }}
    </p>
    <iframe
      allow="autoplay; encrypted-media"
      allowfullscreen
      class="mx-auto my-32 block h-240 w-full md:h-320 md:w-448"
      frameborder="0"
      height="315"
      :src="video404 as string"
      :title="i18n(translations.beCool)"
      width="420"
    />
    <p class="body-1">
      {{ i18n(translations.relaxed) }}
    </p>
    <h3 class="body-1-bold">
      {{ i18n(translations.text) }}
    </h3>

    <RevButton
      v-if="buttonRoute"
      class="mt-32"
      data-test="cta-button"
      :to="buttonRoute"
      variant="primary"
    >
      {{ i18n(translations.cta) }}
    </RevButton>
  </RevContainer>
</template>

<script lang="ts" setup>
import { type NuxtError } from '#app'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import type { Location } from '@ds/types/Location'

import translations from './Error4xx.translations'

const { features } = useMarketplace()
const i18n = useI18n()

const { video404 } = features

defineProps<{
  error: NuxtError
  buttonRoute?: Location
}>()
</script>
