import type { EventData } from '../types'

import { EVENT_PLATFORM } from './constants'
import { brazeFavoriteTrackingModel } from './models/favorites'
import { productModel } from './models/product'

import { sendEvent } from '.'

export function trackSearchTerm(query: string) {
  const eventName = 'search_term'
  const payload = {
    event_platform: EVENT_PLATFORM,
    search_term: query,
  }

  sendEvent(eventName, payload)
}

export function trackBuybackDeviceSpecifications(data: EventData) {
  const eventName = 'buybackDeviceSpecifications'
  sendEvent(eventName, data)
}

export function trackProduct(product: EventData) {
  const eventName = 'product'
  const payload = {
    ...productModel(product),
    event_platform: EVENT_PLATFORM,
  }

  sendEvent(eventName, payload)
}

export function trackAddToMyFavorites(favorite: EventData) {
  const eventName = 'added_to_favorites'
  const payload = {
    ...brazeFavoriteTrackingModel(favorite),
    event_platform: EVENT_PLATFORM,
  }

  sendEvent(eventName, payload)
}

export function trackFavoritesPage({ favorites }: { favorites: EventData[] }) {
  const eventName = 'favorites_page_viewed'
  const payload = {
    number_of_items_on_list: favorites.length,
    event_platform: EVENT_PLATFORM,
  }

  sendEvent(eventName, payload)
}

export function trackAddToCart(product: EventData) {
  const eventName = 'add_to_cart'
  const payload = {
    ...productModel(product),
    event_platform: EVENT_PLATFORM,
  }

  sendEvent(eventName, payload)
}

export function trackBuyBackPlayStationPartnership() {
  const eventName = 'buyback_creation_playstation'

  sendEvent(eventName, {})
}

export function trackRecyclingOrder() {
  const eventName = 'recycling_created'

  sendEvent(eventName, {})
}
