import type { RouteRecordName } from 'vue-router'

import { MarketCountryCode } from '@backmarket/http-api'
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals'

import { trackWebPerformanceAll } from './amplitude/events'
import { trackRumView, trackWebPerformance } from './noodle/events'
import type { PageViewData, WebPerformanceData } from './types'

function getConnection() {
  if (!process.client) {
    return 'unknown'
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType
  return window.navigator?.connection?.effectiveType || 'unknown'
}

function getDeviceType() {
  // only works on client side, so we are mobile first on server side
  if (!process.client) {
    return 'mobile'
  }

  // TODO [FRONT-827] This need to be shared between modules.
  const breakpoints = [
    '(min-width: 992px)',
    '(min-width: 768px)',
    '(min-width: 480px)',
    '(max-width: 480px)',
  ]

  const matchMedia = (media: string) => window.matchMedia(media).matches
  const breakpoint = breakpoints.length - breakpoints.findIndex(matchMedia) - 1

  return breakpoint < 2 ? 'mobile' : 'desktop'
}

function startMonitoringWebVitals(details: PageViewData) {
  const queue = new Set<WebPerformanceData>()

  // We define this variable here to ensure that only one WebPerformanceAll event is pushed to Amplitude.
  let hasFlushed = false

  const composeTrackWebPerformance = (data: WebPerformanceData) => {
    queue.add(data)
    trackWebPerformance({ ...details, ...data })
  }

  const flushQueue = () => {
    if (queue.size > 0 && !hasFlushed) {
      const formattedMetricsToSend: { [metric: string]: number } = Array.from(
        queue,
      ).reduce((acc, { metric, value }) => {
        return {
          ...acc,
          [metric]: value,
        }
      }, {})

      trackWebPerformanceAll({
        ...details,
        ...formattedMetricsToSend,
      })

      hasFlushed = true
      queue.clear()
    }
  }

  onCLS(({ value }) =>
    composeTrackWebPerformance({
      metric: 'cls',
      value,
    }),
  )

  onFCP(({ value }) =>
    composeTrackWebPerformance({
      metric: 'fcp',
      value,
    }),
  )

  onFID(({ value }) =>
    composeTrackWebPerformance({
      metric: 'fid',
      value,
    }),
  )

  onLCP(({ value }) =>
    composeTrackWebPerformance({
      metric: 'lcp',
      value,
    }),
  )

  onTTFB(({ value }) =>
    composeTrackWebPerformance({
      metric: 'ttfb',
      value,
    }),
  )

  onINP(({ value }) =>
    composeTrackWebPerformance({
      metric: 'inp',
      value,
    }),
  )

  // Report all available metrics whenever the page is backgrounded or unloaded.
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushQueue()
    }
  })

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded. If Safari support is needed, you should also flush
  // the queue in the `pagehide` event.
  window.addEventListener('pagehide', flushQueue)
}

export function initWebVitals({
  routeName,
  country,
}: {
  routeName: RouteRecordName
  country: MarketCountryCode
}) {
  const connection = getConnection()
  const deviceType = getDeviceType()

  const data = {
    pageType: String(routeName),
    connection,
    deviceType,
    country,
  }

  startMonitoringWebVitals(data)
  trackRumView(data)
}
