export default {
  confirmationTitle: {
    id: 'buyback_recycling_confirmation_title',
    defaultMessage: 'Nice job!',
  },
  confirmationDescription: {
    id: 'buyback_recycling_confirmation_description',
    defaultMessage:
      'Check your email for your prepaid shipping label. You can also get it via the download button below.',
  },
  confirmationShortDescription: {
    id: 'buyback_recycling_confirmation_short_description',
    defaultMessage: 'Check your email for your prepaid shipping label.',
  },
  confirmationPrintButtonTitle: {
    id: 'buyback_recycling_confirmation_print_button_title',
    defaultMessage: 'Print shipping label',
  },
  confirmationNextStepsTitle: {
    id: 'buyback_recycling_confirmation_next_steps_title',
    defaultMessage: "What's next",
  },
  confirmationNextStepsDataTitle: {
    id: 'buyback_recycling_confirmation_next_steps_data_title',
    defaultMessage: 'Remove your personal data',
  },
  confirmationNextStepsDataDescription: {
    id: 'buyback_recycling_confirmation_next_steps_data_description',
    defaultMessage:
      'If your device still turns on, back up your data and delete it from the device. Otherwise, the recycler will do a factory reset on it. Also, remove the SIM card, if possible.',
  },
  confirmationNextStepsDataLink: {
    id: 'buyback_recycling_confirmation_next_steps_data_link',
    defaultMessage: 'See how to remove your data',
  },
  confirmationNextStepsPackTitle: {
    id: 'buyback_recycling_confirmation_next_steps_pack_title',
    defaultMessage: 'Pack your device',
  },
  confirmationNextStepsPackDescription: {
    id: 'buyback_recycling_confirmation_next_steps_pack_description',
    defaultMessage:
      'Use a small box (up to 500 grams) or envelope. Please note:\n • Don’t ship swollen/visible batteries. It’s forbidden.\n • Remove accessories (i.e. cover, cables, earphones).',
  },
  confirmationNextStepsShipTitle: {
    id: 'buyback_recycling_confirmation_next_steps_ship_title',
    defaultMessage: 'Ship your device',
  },
  confirmationNextStepsShipDescription: {
    id: 'buyback_recycling_confirmation_next_steps_ship_description',
    defaultMessage: '{dropOffLink, html} or {laPosteLink, html}',
  },
  confirmationNextStepsDropOff: {
    id: 'buyback_recycling_confirmation_next_steps_drop_off',
    defaultMessage: 'Find a drop-off point',
  },
  confirmationNextStepsLaPoste: {
    id: 'buyback_recycling_confirmation_next_steps_la_poste',
    defaultMessage: 'La Poste mailbox',
  },
  confirmationCloseCta: {
    id: 'buyback_recycling_confirmation_close_cta',
    defaultMessage: 'Done and dusted',
  },
  closeButtonLabel: {
    id: 'buyback_modal_close_label',
    defaultMessage: 'close',
  },
  previousPage: {
    id: 'buyback_common_previous_page',
    defaultMessage: 'Back to previous page',
  },
  stepperClose: {
    id: 'buyback_common_stepper_close',
    defaultMessage: 'Close',
  },
  stepperCompleted: {
    id: 'buyback_common_stepper_completed',
    defaultMessage: 'Completed',
  },
  stepperCurrent: {
    id: 'buyback_common_stepper_current',
    defaultMessage: 'Current',
  },
  deleteDataNavigationTitle: {
    id: 'buyback_recycling_delete_data_navigation_title',
    defaultMessage: 'Delete your data',
  },
  deleteDataTitle: {
    id: 'buyback_recycling_delete_data_title',
    defaultMessage: 'Disconnect your stuff',
  },
  deleteDataDescription: {
    id: 'buyback_recycling_delete_data_description',
    defaultMessage:
      "First, back up your data before you delete it from your device completely. If you can't delete your data, don’t worry, our recycler will do it for you immediately upon receiving the device.",
  },
  deleteDataBackupLinkTitle: {
    id: 'buyback_recycling_delete_data_backup_link_title',
    defaultMessage: 'Learn how to backup your data',
  },
  deleteDataSubtitle: {
    id: 'buyback_recycling_delete_data_subtitle',
    defaultMessage:
      'Finally, disconnect your personal accounts (iCloud, Google, etc.).',
  },
  deleteDataIcloudLinkTitle: {
    id: 'buyback_recycling_delete_data_icloud_link_title',
    defaultMessage: 'Learn how to disconnect an iCloud account',
  },
  deleteDataGoogleLinkTitle: {
    id: 'buyback_recycling_delete_data_google_link_title',
    defaultMessage: 'Learn how to disconnect an Google account',
  },
  stepCategory: {
    id: 'buyback_recycling_step_category',
    defaultMessage: 'Category',
  },
  stepDevice: {
    id: 'buyback_recycling_funnel_device_title',
    defaultMessage: 'Device',
  },
  stepShippingLabel: {
    id: 'buyback_recycling_confirmation_header',
    defaultMessage: 'Shipping label',
  },
  categoryTitle: {
    id: 'buyback_recycling_category_title',
    defaultMessage: 'What kind of device do you want to recycle?',
  },
  categorySmartphone: {
    id: 'buyback_recycling_funnel_category_smartphone',
    defaultMessage: 'Smartphone',
  },
  categoryNavigationTitle: {
    id: 'buyback_recycling_funnel_navigation_title',
    defaultMessage: 'Recycling',
  },
  loadingTitle: {
    id: 'buyback_recycling_funnel_loading_title',
    defaultMessage: 'Loading...',
  },
  loadingFirstText: {
    id: 'buyback_recycling_funnel_loading_first_text',
    defaultMessage:
      'Humans produce e-waste equal to 7,342 Eiffel Towers each year.',
  },
  loadingSecondText: {
    id: 'buyback_recycling_funnel_loading_second_text',
    defaultMessage: 'Congrats on being part of the solution!',
  },
}
