import { createHttpEndpoint } from '../../utils'
import type { BuybackOrderConversation } from '../models'

/**
 * Get buyback messages on a given order
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Refurbisher/operation/get-bm-buyback-refurbisher-orders-messages}
 */
export const getSellerOrderMessages =
  createHttpEndpoint<BuybackOrderConversation>({
    method: 'GET',
    operationId: 'getSellerOrderMessages',
    path: '/bm/buyback/v1/refurbisher/orders/:id/messages',
  })
