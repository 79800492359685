import { isBrowser } from '@backmarket/utils/env/isBrowser'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

export const canUseLocalStorage = () => isBrowser() && window.localStorage

export function useLocalStorage() {
  return {
    setItem: (key: string, value: string) => {
      try {
        if (canUseLocalStorage()) {
          window.localStorage.setItem(key, value)
        }
      } catch {
        // fails silently
      }
    },
    getItem: (key: string) => {
      try {
        if (canUseLocalStorage()) {
          return window.localStorage.getItem(key)
        }
      } catch {
        // fails silently
      }

      return null
    },
    removeItem: (key: string) => {
      try {
        if (canUseLocalStorage()) {
          window.localStorage.removeItem(key)
        }
      } catch {
        // fails silently
      }
    },
    hasKey: (key: string) => {
      try {
        if (canUseLocalStorage()) {
          return !isEmpty(window.localStorage.getItem(key))
        }
      } catch {
        // fails silently
      }

      return null
    },
  }
}
