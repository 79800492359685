import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { DISCOUNT } from './routes-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const {
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_STUDENT_DISCOUNT = '',
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MILITARY_DISCOUNT = '',
  } = useRuntimeConfig().public

  const disableStudentDiscountPage =
    !FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_STUDENT_DISCOUNT.includes(
      market.countryCode,
    )

  const disableMilitaryDiscountPage =
    !FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MILITARY_DISCOUNT.includes(
      market.countryCode,
    )

  return [
    {
      path: 'military-discount',
      name: DISCOUNT.MILITARY,
      component: () => import('~/scopes/cms/Landing.vue'),
      props: {
        simpleLayout: true,
      },
      meta: {
        // Required because the MilitaryDiscount page builds a redirect URL based on the current user
        // We need to make sure the user is fetched to build the correct URL server-side
        middleware: ['fetch-user'],
        disableClientSideNavigation: () => disableMilitaryDiscountPage,
      },
    },
    {
      path: 'student-discount',
      name: DISCOUNT.STUDENT,
      component: () => import('~/scopes/cms/Landing.vue'),
      props: {
        simpleLayout: true,
      },
      meta: {
        // Similar to MilitaryDiscount: required because the StudentDiscount page builds a redirect URL based on the current user
        // We need to make sure the user is fetched to build the correct URL server-side

        middleware: ['fetch-user'],
        disableClientSideNavigation: () => disableStudentDiscountPage,
      },
    },
  ]
}
