import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import type { EventData } from '../../types'
import { formatPrice } from '../../utils/formatPrice'

export function amplitudeFavoriteTrackingModel({
  averageRate,
  brand,
  category,
  color,
  currency,
  hasNewBattery,
  mobileDeeplink,
  model,
  name,
  price,
  list = 'my_favorites',
  productUuid,
  webUrl,
  position,
  isDisabled = false,
}: EventData) {
  const productPrice = currency
    ? formatPrice({ amount: price, currency })
    : price

  return removeEmptyValuesInObject({
    averageRate,
    product_available: !isDisabled,
    product_brand: brand,
    product_category: category,
    product_color: color,
    product_has_new_battery: hasNewBattery,
    product_id: productUuid,
    product_list: list,
    product_mobile_deeplink: mobileDeeplink,
    product_model: model,
    product_name: name,
    product_position: position,
    product_price: productPrice,
    product_price_amount: price,
    product_price_currency: currency,
    product_web_url: webUrl,
  })
}

export const amplitudeFavoriteTrackingModelCollection = (
  favorites: EventData[] = [],
) => {
  const favoriteProducts = Array.isArray(favorites) ? favorites : [favorites]

  return favoriteProducts.map((favoriteProduct, index) => ({
    ...amplitudeFavoriteTrackingModel({
      ...favoriteProduct,
      position: index + 1,
    }),
  }))
}
