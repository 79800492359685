export default {
  close: {
    id: 'buyback_modal_close_label',
    defaultMessage: 'close',
  },
  backButtonLabel: {
    id: 'buyback_modal_back_label',
    defaultMessage: 'back',
  },
}
