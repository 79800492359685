import type { Currency, Locale } from '@backmarket/http-api'

import createPriceFormatter from './createPriceFormatter'

export default function createCurrencySymbolFormatter(
  locale: Locale,
  defaultCurrency: Currency,
) {
  return (currency = defaultCurrency, options = {}) =>
    createPriceFormatter(locale, currency)(
      { amount: '0.00', currency },
      options,
    )
      .replace(/[\d.,\s]/g, '')
      .trim()
}
