import type { Locale } from '@backmarket/http-api'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'

export default function createLocaleFormatter(locale: Locale) {
  return (localeToTranslate: Locale) => {
    if (typeof Intl.DisplayNames !== 'function') {
      return localeToTranslate
    }

    const languageNames = new Intl.DisplayNames([toBcp47Locale(locale)], {
      type: 'language',
      languageDisplay: 'standard',
    })

    return (
      languageNames.of(toBcp47Locale(localeToTranslate)) || localeToTranslate
    )
  }
}
