import {
  Currency,
  Locale,
  type Market,
  MarketCountryCode,
  MarketStatus,
  MarketplaceCode,
} from '@backmarket/http-api'

export const MARKETS: Record<MarketCountryCode, Market> = {
  [MarketCountryCode.AT]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.de_AT,
    domain: '.backmarket.at',
    locales: [Locale.de_AT],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.AT,
  },

  [MarketCountryCode.AU]: {
    defaultCurrency: Currency.AUD,
    defaultLocale: Locale.en_AU,
    domain: '.backmarket.com.au',
    locales: [Locale.en_AU],
    marketplace: MarketplaceCode.AP,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.AU,
  },

  [MarketCountryCode.BE]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.fr_BE,
    domain: '.backmarket.be',
    locales: [Locale.fr_BE],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.BE,
  },

  [MarketCountryCode.DE]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.de_DE,
    domain: '.backmarket.de',
    locales: [Locale.de_DE],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.DE,
  },

  [MarketCountryCode.ES]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.es_ES,
    domain: '.backmarket.es',
    locales: [Locale.es_ES],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.ES,
  },

  [MarketCountryCode.FI]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.fi_FI,
    domain: '.backmarket.fi',
    locales: [Locale.fi_FI],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.FI,
  },

  [MarketCountryCode.FR]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.fr_FR,
    domain: '.backmarket.fr',
    locales: [Locale.fr_FR],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.FR,
  },

  [MarketCountryCode.GB]: {
    defaultCurrency: Currency.GBP,
    defaultLocale: Locale.en_GB,
    domain: '.backmarket.co.uk',
    locales: [Locale.en_GB],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.GB,
  },

  [MarketCountryCode.GR]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.el_GR,
    domain: '.backmarket.gr',
    locales: [Locale.el_GR],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.GR,
  },

  [MarketCountryCode.IE]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.en_IE,
    domain: '.backmarket.ie',
    locales: [Locale.en_IE],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.IE,
  },

  [MarketCountryCode.IT]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.it_IT,
    domain: '.backmarket.it',
    locales: [Locale.it_IT],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.IT,
  },

  [MarketCountryCode.JP]: {
    defaultCurrency: Currency.JPY,
    defaultLocale: Locale.ja_JP,
    domain: '.backmarket.co.jp',
    locales: [Locale.ja_JP],
    marketplace: MarketplaceCode.AP,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.JP,
  },

  [MarketCountryCode.NL]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.nl_NL,
    domain: '.backmarket.nl',
    locales: [Locale.nl_NL],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.NL,
  },

  [MarketCountryCode.PT]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.pt_PT,
    domain: '.backmarket.pt',
    locales: [Locale.pt_PT],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.PT,
  },

  [MarketCountryCode.SE]: {
    defaultCurrency: Currency.SEK,
    defaultLocale: Locale.sv_SE,
    domain: '.backmarket.se',
    locales: [Locale.sv_SE],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.SE,
  },

  [MarketCountryCode.SK]: {
    defaultCurrency: Currency.EUR,
    defaultLocale: Locale.sk_SK,
    domain: '.backmarket.sk',
    locales: [Locale.sk_SK],
    marketplace: MarketplaceCode.EU,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.SK,
  },

  [MarketCountryCode.US]: {
    defaultCurrency: Currency.USD,
    defaultLocale: Locale.en_US,
    domain: '.backmarket.com',
    locales: [Locale.en_US],
    marketplace: MarketplaceCode.NA,
    status: MarketStatus.OPEN,
    countryCode: MarketCountryCode.US,
  },
}

export const DEFAULT_MARKET = MARKETS[MarketCountryCode.US]
