import { type HttpResponsePayload, createHttpEndpoint } from '../../utils'
import type { BuybackOrderConversationMetadata } from '../models'

/**
 * Get conversation message metadata
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Staff/operation/get-bm-staff-buyback-conversation-order-metadata}
 */
export const getStaffOrderMetadata = createHttpEndpoint<
  HttpResponsePayload<BuybackOrderConversationMetadata>
>({
  method: 'GET',
  operationId: 'getStaffOrderMetadata',
  path: '/bm/buyback/v1/staff/orders/:id/messages/metadata',
})
