import { getBrowserCookie } from '@backmarket/utils/cookies/getBrowserCookie'
import amplitude from 'amplitude-js'

import { COOKIES } from '../constants'

import { USER_GROUP_NAME, USER_GROUP_VALUES } from './constants'

// BM is under the "growth plan" of Amplitude
// Documentation: https://amplitude.github.io/Amplitude-JavaScript/

const isUnitTest = process.env.VITEST === 'true'
const isE2ETestingEnvironment =
  window?.navigator?.userAgent === 'MrFatUserAgent'
const isTestingEnvironment = isUnitTest || isE2ETestingEnvironment

export const sendEvent = (eventName: string, payload: object) => {
  if (isTestingEnvironment) return

  amplitude.getInstance().logEvent(eventName, payload)
}

export const setUserProperties = (userProperties: Record<string, unknown>) => {
  if (isTestingEnvironment) return

  amplitude.getInstance().setUserProperties(userProperties)
}

export const setUserId = (userId: string) => {
  if (isTestingEnvironment) return

  amplitude.getInstance().setUserId(userId)
}

export const getDeviceId = () => {
  if (isTestingEnvironment) return ''

  return amplitude.getInstance().getDeviceId()
}

export const setOptIn = (optIn: boolean) => {
  if (isTestingEnvironment) return

  amplitude.getInstance().setOptOut(!optIn)

  // When clicking on the cookie modal or on the cookies page
  // Amplitude needs to be activated if the optOut is false
  if (optIn) {
    amplitude.getInstance().enableTracking()
  }
}

export type InitAmplitudeParameters = {
  apiKey: string
  version: string
  isOptInCookieNeeded: boolean
}

export const initAmplitude = ({
  apiKey,
  version,
  isOptInCookieNeeded,
}: InitAmplitudeParameters) => {
  const hasUserEnabledAnalytics = isOptInCookieNeeded
    ? getBrowserCookie(COOKIES.analytics) === 'true'
    : true

  amplitude.getInstance().init(apiKey, undefined, {
    batchEvents: true,
    deferInitialization: !hasUserEnabledAnalytics,
    disableCookies: false,
    eventUploadPeriodMillis: 5000,

    // Marketing
    includeFbclid: true,
    includeGclid: true,
    includeUtm: true,

    onExitPage: () => {
      // this function is invoked when page is unloaded
      // switches to beacon and sends all unsent events immediately
      // see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/migration/?h=onexitpage#flush-or-onexitpage
      // @ts-expect-error `sendEvents()` method is documented but private so we need a workaround to be able to use it
      amplitude.getInstance().sendEvents()
    },

    // serverZone is fixed to EU in order to keep the data out of the US.
    serverZone: 'EU',
    serverZoneBasedApi: true,
  })

  amplitude.getInstance().setVersionName(version)

  amplitude.getInstance().setUserProperties({
    [USER_GROUP_NAME]: USER_GROUP_VALUES.NOT_LOGGED,
  })
}
