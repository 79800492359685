import type { LoggerAttributes } from '@backmarket/front-logger'
import {
  type HttpContext,
  HttpEvent,
} from '@backmarket/http-api/src/utils/types'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import {
  ERROR_TYPE,
  type ErrorType,
  LOGGED_HTTP_STATUS_CODE,
  SQUAD_NAME,
} from './config'

interface LogErrorParams {
  attributes?: LoggerAttributes
  errorTitle: string
  errorType?: Exclude<ErrorType, 'feature_error' | 'fetch_error'>
}

interface LogFeatureErrorParams {
  errorDetail?: string | Record<string, unknown>
  errorTitle: string
  featureName?: string
}

interface LogHttpErrorParams {
  error: Error
  errorStatusCode: number
  errorDetail?: string | Record<string, unknown>
  errorTitle: string
}

interface LogHttpOnEventParams<T, B> {
  event: HttpEvent
  context: HttpContext<T, B>
  errorDetail?: string | Record<string, unknown>
  errorTitle: string
}

interface useSalesCustomerCareLoggerReturnValue {
  logError: ({ attributes, errorTitle }: LogErrorParams) => void
  logFeatureError: ({
    errorDetail,
    errorTitle,
    featureName,
  }: LogFeatureErrorParams) => void
  logHttpError: ({ error, errorDetail, errorTitle }: LogHttpErrorParams) => void
  logHttpOnEvent: <T, B>({
    context,
    event,
    errorDetail,
    errorTitle,
  }: LogHttpOnEventParams<T, B>) => void
}

export const useSalesCustomerCareLogger =
  (): useSalesCustomerCareLoggerReturnValue => {
    const logger = useLogger()

    const logError = ({
      attributes,
      errorTitle,
      errorType,
    }: LogErrorParams) => {
      logger.error(`${errorTitle}`, {
        ...attributes,
        error_context: {
          error_type: errorType ?? ERROR_TYPE.uncategorized,
          squad_name: SQUAD_NAME,
        },
      })
    }

    const logFeatureError = ({
      errorDetail,
      errorTitle,
      featureName,
    }: LogFeatureErrorParams) => {
      logger.error(`${errorTitle}`, {
        error_context: {
          feature_name: featureName ?? '',
          error_type: ERROR_TYPE.feature,
          error_detail: errorDetail ?? '',
          squad_name: SQUAD_NAME,
        },
      })
    }

    const logHttpError = ({
      error,
      errorStatusCode,
      errorDetail = '',
      errorTitle,
    }: LogHttpErrorParams) => {
      if (LOGGED_HTTP_STATUS_CODE.includes(errorStatusCode)) {
        logger.error(`${errorTitle}`, {
          error,
          error_context: {
            error_details: errorDetail,
            error_type: ERROR_TYPE.fetch,
            error_status_code: errorStatusCode,
            squad_name: SQUAD_NAME,
          },
        })
      }
    }

    const logHttpOnEvent = <T, B>({
      context,
      errorTitle,
      event,
    }: LogHttpOnEventParams<T, B>) => {
      if (event === HttpEvent.Fail && context.error) {
        logHttpError({
          error: context.error,
          errorStatusCode: context.error.status,
          errorTitle,
        })
      }
    }

    return {
      logError,
      logFeatureError,
      logHttpError,
      logHttpOnEvent,
    }
  }
