<template>
  <RevContainer class="py-24 text-center">
    <h1 class="heading-1 mb-16">
      {{ i18n(translations.title) }}
    </h1>

    <div
      class="rounded-lg mb-20 inline-block bg-[#fe9ce5] p-24 sm:mb-32 sm:px-56 sm:py-40"
    >
      <RevIllustration
        alt=""
        :height="300"
        src="/img/not-found.svg"
        :width="300"
      />
    </div>

    <p class="body-1-bold">
      {{ i18n(translations.itIsUs) }}
    </p>
    <p class="body-1-bold">
      {{ i18n(translations.weTry) }}
    </p>
    <p class="body-1">
      {{ i18n(translations.retry) }}
    </p>
    <p class="body-1">
      {{ i18n(translations.retryParenthesis) }}
    </p>
    <div class="mt-24">
      <p class="body-1-bold">Error {{ error.statusCode }}</p>
      <p class="body-1">
        {{ i18n(translations.serverError) }}
      </p>
    </div>
  </RevContainer>

  <section
    v-if="shouldDisplayStacktrace"
    class="bg-static-default-mid text-body-2 mt-24 flex justify-center"
  >
    <div class="overflow-auto p-12">
      <p class="heading-3 pb-12">{{ error.message }}</p>
      <!-- eslint-disable vue/no-v-html -->
      <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
      <pre v-html="stacktrace"></pre>
      <!-- eslint-enable -->
    </div>
  </section>
</template>

<script lang="ts" setup>
import { type NuxtError, useRuntimeConfig } from '#app'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Error500.translations'

const i18n = useI18n()

const props = defineProps<{ error: NuxtError }>()
const runtimeConfig = useRuntimeConfig()

const shouldDisplayStacktrace = computed(
  () => process.dev && runtimeConfig.public.DEV_ERROR_DETAILS_ENABLED,
)

const stacktrace = props.error.stack || ''
</script>

<style type="text/css">
.stack.internal {
  @apply m-20;
}
</style>
