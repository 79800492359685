import { fromEntries } from './fromEntries'

/**
 * Creates an object with the same keys as `object` and values generated by
 * running each own enumerable property of object through `mapper`. The mapper
 * function is invoked with three arguments:
 * - `value`: the current element's value
 * - `key`: the current element's key
 * - `obj`: the original object
 *
 * @param object The input object
 * @param mapper A function that will be called on every element of the object
 * @returns An object containing the same (enumerable) keys as `object`,
 * where values have been mapped from the input object through the `mapper`
 * function.
 */
export function mapValues<InputValue, OutputValue = InputValue>(
  object: Record<PropertyKey, InputValue>,
  mapper: (
    value: InputValue,
    key: PropertyKey,
    obj: typeof object,
  ) => OutputValue,
): Record<PropertyKey, OutputValue> {
  return fromEntries(
    Object.entries(object).map(([key, value]) => [
      key,
      mapper(value, key, object),
    ]),
  )
}
