import * as amplitude from './amplitude/context'
import * as braze from './braze'
import * as googleTagManager from './google-tag-manager/context'
import * as noodle from './noodle/context'
import type { ContextData, UserContext } from './types'

export function setUserContext(userContext: UserContext) {
  amplitude.setUserContext(userContext)
  googleTagManager.setUserContext(userContext)
  noodle.setUserContext(userContext)
  braze.setUserContext(userContext)
}

export function setContext(context: ContextData) {
  googleTagManager.setContext(context)
  noodle.setContext(context)
  amplitude.setContext(context)
}
