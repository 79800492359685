import type { Router } from 'vue-router'

import type { Link } from '@backmarket/http-api'
import type { ContentLink } from '@backmarket/http-api/src/api-specs-content/models/content-link'
import { pick } from '@backmarket/utils/object/pick'

import { transformHref } from '~/scopes/cms/helpers/link/transformHref'

export function buildGetArticleHrefFn({
  router,
  currentUrl,
}: {
  router: Router
  currentUrl: URL
}) {
  return (link: Link): ContentLink => {
    const href =
      'href' in link
        ? link.href || ''
        : router.resolve(pick(link, ['name', 'params', 'query'])).href

    return transformHref({ href, currentUrl })
  }
}
