import { defineNuxtRouteMiddleware, useRuntimeConfig } from '#imports'

import { useAuthStore } from '../composables/useAuthStore'
import { useUserStore } from '../composables/useUserStore'

/**
 * The guest middleware.
 *
 * It makes sure that people are guest (non-authenticated).
 *
 * This middleware is globally available as soon as you register the OAuth
 * module in your application. You do not have to do anything to start
 * filtering page views.
 *
 * In order to prevent access to a page to authenticated people, you have
 * to provide some route meta configuration using the `meta.guest` property.
 *
 * Only the `required` value is mandatory. If set to `true`, access will be
 * prevented to authenticated people. If set to `false`, then everyone will
 * be able to access the page (both guest and authenticated users).
 *
 * @example
 * const route = {
 *   name: 'login',
 *   path: '/login',
 *   component: () => import('./components/Login'),
 *   meta: {
 *     guest: {
 *       required: true,
 *     },
 *   },
 * }
 *
 * If the access is prevented, the user will be redirected to the redirect
 * location configured when the module was instantiated. This is configured
 * via the `defaultRedirectLocationWhenAuthenticated` module option.
 *
 * However, it is possible to define a custom redirection location for a
 * specific route. This is achieved via the `meta.guest.redirection` property.
 *
 * @example
 * const route = {
 *   name: 'login',
 *   path: '/login',
 *   component: () => import('./components/Login'),
 *   meta: {
 *     auth: {
 *       required: true,
 *       redirection: { name: 'home' }
 *     },
 *   },
 * }
 */
export default defineNuxtRouteMiddleware(async (route) => {
  const config = useRuntimeConfig().public.oauth
  const authStore = useAuthStore()
  const userStore = useUserStore()

  // Matches are listed starting from the first parent, and going through every route
  // until we hit the current route. For example, having three routes (A > B > C)
  // would generate a `matched` array [A, B, C]. Since the route defined latter
  // should take precedence over its parents, we reverse the whole array.
  const reversed = [...route.matched].reverse()

  // Since we reversed the array beforehand, we can now use `Array#find` to traverse
  // the whole array and return the _first_ route matching the given predicate.
  const match = reversed.find(
    (routeDefinition) => routeDefinition.meta && routeDefinition.meta.guest,
  )

  // We may request a routes tree not protected at all.
  if (match === undefined) return
  if (match.meta.guest === undefined) return
  if (match.meta.guest.required === false) return

  // We don't want to fire the request each time the middleware is instanciated.
  // If the authentication status was previously fetched already, there is no
  // need to fetch it again, and we should use the data saved in our store.
  if (authStore.fetched === false) {
    await userStore.fetchUser()
  }

  // We're good to go, we're a guest.
  if (authStore.authenticated === false) return

  // We are expected to return different values based on the expected behaviour.
  // eslint-disable-next-line consistent-return
  return (
    match.meta.guest.redirection ||
    config.defaultRedirectLocationWhenAuthenticated
  )
})
