export const ROUTE_NAMES = {
  DASHBOARD: {
    SELF: 'dashboard',
    FAVORITES: 'favorites',
    MY_ORDERS: {
      SELF: 'orders',
      WITHDRAW: {
        INSURANCES: 'withdraw_insurances',
      },
    },
    MY_PETANQUE_ORDERS: 'petanque_orders',
    MY_SALES: 'my_sales',
    KYC: {
      OTHER: 'dashboard_other',
      PROFILE: 'profile',
      DELETE_ACCOUNT: 'delete_account',
      REFER_FRIEND: 'refer_friend',
    },
  },
}

export const MODAL_NAMES = {
  KYC: {
    PROFILE_PASSWORD_EDIT: 'profile_password_edit',
    PROFILE_INFO_EDIT: 'profile_info_edit',
    RIB_UPLOAD_MODAL: 'RIB_UPLOAD_MODAL',
    RIB_UPLOAD_CONFIRMATION_MODAL: 'RIB_UPLOAD_CONFIRMATION_MODAL',
  },
}

export const ENDPOINTS_PATH = {
  IDENTITY_DOCUMENTS: '/bm/client/identity_documents',
  BANK_DETAILS: '/bm/client/bank_details',
  BIRTHDATE: '/buyback/v1/customer/orders/:id/birthdate',
  CUSTOMER_IDENTITY: '/payout/customer/identity-number',
  PAYMENT_INFO: '/buyback/v1/customer/orders/:id/payment-info',
}
