<template>
  <div v-if="hasLinks">
    <BlockTitle :subtitle="subtitle" :title="title" />

    <RevButtonTiny
      v-for="(cta, index) in links"
      :key="`${cta.label}_${id}`"
      class="mb-12 mr-12"
      :rel="cta.link?.rel"
      :target="cta.link?.target"
      :to="cta.link?.href"
      variant="primary"
      @click="
        () =>
          trackClick({
            ...tracking,
            position: index + 1,
            name: cta.label,
          })
      "
    >
      {{ cta.label }}
    </RevButtonTiny>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { RelatedLinkProps } from '@backmarket/http-api/src/api-specs-content'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonTiny } from '@ds/components/ButtonTiny'

import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

export type RelatedLinksProps = ContentBlockProps & RelatedLinkProps

const props = withDefaults(defineProps<RelatedLinksProps>(), {
  title: '',
  subtitle: '',
})

const { trackClick } = useTracking()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const hasLinks = computed(() => props.links?.length > 0)
</script>
