export const ROUTES = {
  FORM: 'review_form',
  PAGES: {
    ALL: 'reviews',
    LANDING: 'landing_reviews',
    MERCHANT: 'merchant_reviews',
    PRODUCT: 'product_reviews',
    LEGACY_PRODUCT: 'product_reviews_legacy',
  },
}
