export const ROUTES = {
  AUTH: {
    LOGIN: 'auth/login',
    LOGOUT: 'auth/logout',
    RESET_PASSWORD: 'password-reset',
    RESET_PASSWORD_CONFIRM: 'password-reset/confirm',
    SET_NEW_PASSWORD: 'password-reset/set-new',
    SET_NEW_PASSWORD_EXPIRED: 'password-reset/expired-reset',
  },
}
