import { abortNavigation, defineNuxtRouteMiddleware } from 'nuxt/app'

export default defineNuxtRouteMiddleware((to, from) => {
  const isVisitingCurrentPath = from.fullPath === to.fullPath

  if (
    // skip middleware on server
    process.server ||
    // skip middleware on initial client load
    isVisitingCurrentPath ||
    // skip middleware in local dev, Ingress does not exists locally
    process.dev
  ) {
    return
  }

  if (typeof to.meta?.disableClientSideNavigation === 'function') {
    const isClientSideNavigationDisabled = to.meta.disableClientSideNavigation({
      to,
      from,
    })

    if (isClientSideNavigationDisabled) {
      window.location.assign(to.fullPath)

      // eslint-disable-next-line consistent-return
      return abortNavigation()
    }
  }
})
