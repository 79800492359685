import type { H3Event } from 'h3'

import type { Market } from '@backmarket/http-api'

import { MARKETS } from '../config'

import { getRequestCountryCode } from './getRequestCountryCode'

/**
 * Return the market object associated with the given event.
 *
 * @example
 * const market = getRequestMarket(event)
 *
 * @note
 * Note that this function signature follows the same convention as all `h3`
 * helpers. It's named `getRequestXYZ` and accepts an event as parameter.
 */
export function getRequestMarket(event: H3Event): Market {
  return MARKETS[getRequestCountryCode(event)]
}
