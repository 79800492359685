interface setCookieOptions {
  domain?: string
  maxAge?: string | number
  expires?: string
  path?: string
  secure?: boolean
  httpOnly?: boolean
}

/**
 * Set the value in cookies using optional options.
 */
export function setBrowserCookie(
  cookieName: string,
  value: string,
  options: setCookieOptions = {},
) {
  const {
    domain,
    maxAge,
    expires,
    path,
    secure = false,
    httpOnly = false,
  } = options

  const domainParam = domain ? `; domain=${domain}` : ''
  const pathParam = path ? `; path=${path}` : ''
  const maxAgeParam = maxAge ? `; max-age=${maxAge}` : ''
  const expiresParam = expires ? `; expires=${expires}` : ''
  const secureParam = secure ? '; secure' : ''
  const httpOnlyParam = httpOnly ? '; HttpOnly' : ''

  document.cookie = `${cookieName}=${value}${domainParam}${pathParam}${maxAgeParam}${expiresParam}${secureParam}${httpOnlyParam}`
}
