export const CUSTOMER_CARE = {
  DIAGNOSIS: {
    SELF: 'diagnosis',
  },
  RESOLUTION: {
    SELF: 'resolution',
    RESOLUTION_CONTACT_CONFIRMATION: 'resolution_contact_confirmation',
    RESOLUTION_RETURN_CONFIRMATION: 'resolution_return_confirmation',
    RESOLUTION_STRAIGHT_REFUND_CONFIRMATION:
      'resolution_straight_refund_confirmation',
  },
}
