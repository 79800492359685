import humps, { type OptionOrProcessor } from 'humps'

/**
 * Recursively transform object keys from snake_case to camelCase.
 *
 * This helper is here to provide better type safety around the underlying
 * methods from the `humps` package. We want to throw type errors when
 * this is called with class instances (like FormData, URLSearchParams).
 *
 * @example
 * camelizeKeys({ hello_world: 'hello' }) // { helloWorld: 'hello' }
 */
export function camelizeKeys<
  Type extends
    | Record<string, unknown>
    | Array<Record<string, unknown>>
    | ReadonlyArray<Record<string, unknown>>,
>(object: Type, optionsOrProcessor?: OptionOrProcessor) {
  return humps.camelizeKeys<Type>(object, optionsOrProcessor)
}
