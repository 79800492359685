import { useRuntimeConfig } from '#imports'

/**
 * Filter the public config to only keep the feature flags
 */
export const useFeatureFlags = () => {
  const { public: publicConfig } = useRuntimeConfig()

  return Object.entries(publicConfig).reduce(
    (flags, [key, value]) => {
      if (key.startsWith('FF_')) {
        // eslint-disable-next-line no-param-reassign
        flags[key] = value
      }

      return flags
    },
    {} as Record<string, unknown>,
  )
}
