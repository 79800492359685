import type { GetOfferResponseLegacy } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferLegacy'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { defineStore } from 'pinia'

interface State {
  offer: GetOfferResponseLegacy | null
}

function getBuyBackOffer(): State['offer'] {
  const offer = localStorage.getItem('buyback_offer')

  return offer ? JSON.parse(offer) : null
}

export const useBuyBackStore = defineStore('buyBackStore', {
  state: (): State => ({
    offer: null,
  }),
  getters: {
    hasOffer: (state): boolean => !isEmpty(state.offer),
  },
  actions: {
    resetBuyBackOffer() {
      this.offer = null
      localStorage.removeItem('buyback_offer')
    },
    setBuyBackOffer(offer: GetOfferResponseLegacy, shouldStoreLocally = true) {
      this.offer = offer
      if (shouldStoreLocally) {
        localStorage.setItem('buyback_offer', JSON.stringify(offer))
      }
    },
  },
  hydrate(state) {
    state.offer = getBuyBackOffer()
  },
})
