<template>
  <NuxtLink v-bind="formattedProps">
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
/**
 * API Link model does not comply with vue-router Link type.
 * We are adding this code to align the behavior of Links with "useRouter".
 */
import { computed } from 'vue'

import type { Location } from '@backmarket/design-system/types/Location'

import type { NuxtLinkProps } from '../types/NuxtLinkProps'
import { toNuxtLink } from '../utils/toNuxtLink'

interface CustomLinkProps extends Omit<NuxtLinkProps, 'to'> {
  to: NuxtLinkProps['to'] | Location
}

const props = defineProps<CustomLinkProps>()

const formattedProps = computed(() => {
  /**
   * Some props pairs cannot be used together in NuxtLink like
   * prefetch/noPrefetch and rel/noRel.
   * `defineProps` set both to false which will lead to a warning.
   * We deprecate noPrefetch/noRel in favor of prefetch/rel props
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { noPrefetch, noRel, ...originalProps } = props

  return {
    ...originalProps,
    to: originalProps.to ? toNuxtLink(originalProps.to) : undefined,
  }
})
</script>
