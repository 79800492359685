/**
 * Get the value of a given cookie name.
 *
 * @param {string} cookieName The name of the cookie we want the value of.
 * @return {*} The value of the cookie.
 */
export const getBrowserCookie = (cookieName: string) => {
  const cookieStrings = document.cookie.split('; ')
  const cookieKeysAndValues = cookieStrings.map((cookie) => cookie.split('='))

  const cookies = new Map(cookieKeysAndValues as [string, string][])

  return cookies.get(cookieName)
}
