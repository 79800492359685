import { useRuntimeConfig } from '#imports'

import {
  type LoggerConfiguration,
  createConsoleTransport,
  createDatadogTransport,
} from '@backmarket/front-logger'

export function getTransports() {
  const config = useRuntimeConfig().public.logger
  const configConsole = config.transportsConfig.console
  const configDatadog = config.transportsConfig.datadog

  const transports: LoggerConfiguration['transports'] = []

  if (
    (process.client && configConsole.isEnabled.client) ||
    (process.server && configConsole.isEnabled.server)
  ) {
    transports.push(createConsoleTransport())
  }

  if (
    (process.client && configDatadog.isEnabled.client) ||
    (process.server && configDatadog.isEnabled.server)
  ) {
    const transport = createDatadogTransport({
      datadogConfiguration: {
        clientToken: configDatadog.clientToken,
        sessionSampleRate: 100,
        site: 'datadoghq.com',

        // Stop forwarding console.error logs, uncaught exceptions and network
        // errors to Datadog. We already do that with our own custom logger
        // (see logger.wrapConsole).
        forwardErrorsToLogs: false,

        beforeSend: (event) => {
          // The Android Facebook/Instagram apps were injecting scripts when
          // loading pages in webviews. These were doing console.info calls
          // which ended up in our DD.
          if (event.message.startsWith('FBNav')) {
            return false
          }

          // Using the translation functionality in the iOS Edge app (the browser)
          // causes this error to be thrown in big bursts.
          if (
            event.message.startsWith(
              'Promise does not exist for the provided text',
            )
          ) {
            return false
          }

          return true
        },
      },
    })

    transports.push(transport)
  }

  return transports
}
