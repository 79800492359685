import { mapValues } from '@backmarket/utils/object/mapValues'

import { config } from './config'

export const EXPERIMENTS_HEADER = 'bm-experiments'

/**
 * Get experiments received through the experiments HTTP header.
 *
 * Note that we accept HTTP headers as parameter. This is because we use this
 * helper in both Nuxt and Nitro scopes, and they do not provide the same kind
 * of abstractions to retrieve such headers.
 *
 * - On Nuxt side, you can use `useRequestHeaders`.
 * - On Nitro side, you can use `getRequestHeaders`.
 *
 * Also, while being essentially the same, both helpers do not share the same
 * return signature. So, we picked one that matches both native helpers.
 *
 * @example - Nuxt
 * const headers = useRequestHeaders()
 * const experiments = getExperimentsFromHeaders(headers)
 *
 * @example - Nitro
 * const headers = getRequestHeaders(event)
 * const experiments = getExperimentsFromHeaders(headers)
 */
export function getExperimentsFromHeaders(
  headers: Record<string, string | undefined>,
): Record<string, string> {
  const header = headers[EXPERIMENTS_HEADER] ?? '{}'

  try {
    return JSON.parse(header)
  } catch (error) {
    if (error instanceof Error) {
      // We're using console.error here because we can't retrieve our logger
      // instance via `useLogger`. It's not available in Nitro scope. /shrug
      // Also note that we can't use template strings because of the sast
      // orb firing false positive issues in the CI.
      const message = 'Failed to parse %s header'
      // eslint-disable-next-line no-console
      console.error(message, EXPERIMENTS_HEADER, { error, value: header })
    }

    return {}
  }
}

/**
 * Get default values for all configured experiments.
 *
 * @example
 * const defaultExperiments = getDefaultExperiments()
 */
export function getDefaultExperiments() {
  return mapValues(config, (experiment) => experiment.defaultValue)
}
