import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { ROUTES as AUTH_ROUTES } from '../auth/route-names'

import { ROUTE_NAMES } from './constants'

export const { DASHBOARD } = ROUTE_NAMES

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const {
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MY_ORDERS,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_PROFILE,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MY_OTHER_THINGS,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REFER_A_FRIEND,
  } = useRuntimeConfig().public

  const isMyOrdersFSMEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MY_ORDERS as string
  )?.includes(market.countryCode)

  const isProfileFSMEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_PROFILE as string
  )?.includes(market.countryCode)

  const isMyOtherThingsEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_MY_OTHER_THINGS as string
  )?.includes(market.countryCode)

  const isReferAFriendEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REFER_A_FRIEND as string
  )?.includes(market.countryCode)

  return [
    {
      path: 'dashboard',
      name: DASHBOARD.SELF,
      redirect: { name: DASHBOARD.MY_ORDERS.SELF },
      component: () => import('./layout/DashboardLayout.vue'),
      children: [
        {
          path: 'profile',
          name: DASHBOARD.KYC.PROFILE,
          component: () => import('./profile/Profile.vue'),
          meta: { disableClientSideNavigation: () => !isProfileFSMEnabled },
        },
        {
          path: 'favorites',
          name: DASHBOARD.FAVORITES,
          component: () =>
            import(
              '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
            ),
          meta: { disableClientSideNavigation: () => true },
        },
        {
          path: 'others',
          component: () => import('./my-other-things/MyOtherThings.vue'),
          name: DASHBOARD.KYC.OTHER,
          meta: {
            disableClientSideNavigation: () => !isMyOtherThingsEnabled,
          },
        },
        {
          path: 'orders',
          name: DASHBOARD.MY_ORDERS.SELF,
          component: () => import('./my-orders/MyOrders.vue'),
          meta: {
            disableClientSideNavigation: () => !isMyOrdersFSMEnabled,
          },
        },
        {
          path: 'sales',
          name: DASHBOARD.MY_SALES,
          component: () => import('./my-sales/MySales.vue'),
        },
        {
          path: 'orders/:orderId/orderlines/:orderlineId/insurances/withdraw',
          component: () =>
            import('./insurances-withdraw/InsurancesWithdraw.vue'),
          name: DASHBOARD.MY_ORDERS.WITHDRAW.INSURANCES,
          meta: { disableClientSideNavigation: () => true },
        },
        {
          path: 'past-orders',
          component: () =>
            import(
              '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
            ),
          name: DASHBOARD.MY_PETANQUE_ORDERS,
          meta: { disableClientSideNavigation: () => true },
        },
        {
          path: 'refer-friend',
          component: () => import('./refer-a-friend/ReferAFriend.vue'),
          name: DASHBOARD.KYC.REFER_FRIEND,
          meta: { disableClientSideNavigation: () => !isReferAFriendEnabled },
        },
      ],
      meta: {
        auth: {
          required: true,
          // TODO: [FRONT-1199] remove this after global config change
          redirection: { name: AUTH_ROUTES.AUTH.LOGIN },
        },
        disableClientSideNavigation: () => !isMyOrdersFSMEnabled,
      },
    },
    {
      component: () => import('./profile/DeleteAccount/DeleteAccount.vue'),
      name: DASHBOARD.KYC.DELETE_ACCOUNT,
      path: 'dashboard/profile/delete-account',
      meta: {
        auth: {
          required: true,
          redirection: { name: AUTH_ROUTES.AUTH.LOGIN },
        },
      },
    },
  ]
}
