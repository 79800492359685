import type { ContextData, UserContext } from '../types'

import { pushEventToDataLayer } from '.'

function getFormattedDate(date: string): string | null {
  const lastOrderTimestamp = Date.parse(date)

  if (!Number.isNaN(lastOrderTimestamp)) {
    const ISOStringDate = new Date(lastOrderTimestamp).toISOString()
    const dateTime = ISOStringDate.split('T')[0]

    return dateTime.replace(/-/g, '')
  }

  return null
}

export function setUserContext(userContext: UserContext) {
  const USER_CONTEXT_GTM_EVENT_NAME = 'bmUserContext'
  const formattedLastOrderDate = getFormattedDate(userContext.lastOrderDate)

  pushEventToDataLayer({
    clientId: userContext.clientId,
    emailHashed: userContext.emailHashed,
    event: USER_CONTEXT_GTM_EVENT_NAME,
    isStaff: userContext.isStaff,
    lastOrderDate: formattedLastOrderDate,
    lifetimeRevenue: userContext.lifetimeRevenue,
    merchantId: userContext.merchantId,
    numberOfOrders: userContext.numberOfOrders,
    userId: userContext.userId,
  })
}

export function setContext(context: ContextData) {
  const USER_CONTEXT_GTM_EVENT_NAME = 'bmContext'

  pushEventToDataLayer({
    event: USER_CONTEXT_GTM_EVENT_NAME,
    platform: context.service,
    ...context,
  })
}
