import type { H3Event } from 'h3'

import type { Span, Tracer } from 'dd-trace'

/**
 * Trace the given callback using the current `dd-trace` tracer instance.
 *
 * @example
 * const options = { ... }
 * const data = trace(event, 'span.name', options, () => {
 *   return $httpFetch('/api/endpoint')
 * })
 *
 * @warning
 * This function must only be called server-side.
 */
export function trace<T>(
  event: H3Event,
  name: Parameters<Tracer['trace']>[0],
  options: Parameters<Tracer['trace']>[1],
  callback: (span?: Span, tracer?: Tracer) => T,
) {
  // Retrieve the tracer instance from the event object so we pull it indirectly
  // and thus avoid bundling the `dd-trace` dependency client-side for nothing.
  const { tracer } = event.context

  if (tracer) {
    const traceOptions = {
      ...options,
      childOf: tracer.scope().active() || undefined,
    }

    return tracer.trace(name, traceOptions, (span) => {
      return callback(span, tracer)
    })
  }

  return callback()
}
