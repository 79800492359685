<template>
  <RevButton
    :class="widthProps.class"
    :full-width="widthProps.fullWidthStyle"
    :size
    :variant
  >
    <slot></slot>
  </RevButton>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'
import { FullWidthEnum } from '@ds/components/Button/Button.constant'

type CmsButtonBaseProps = {
  width?: 'adaptive' | 'fixed' | 'full-width'
  size?: InstanceType<typeof RevButton>['$props']['size']
  variant?: InstanceType<typeof RevButton>['$props']['variant']
}

const props = withDefaults(defineProps<CmsButtonBaseProps>(), {
  size: 'medium',
  variant: 'primary',
  width: 'fixed',
})

const widthProps = computed(() => {
  switch (props.width) {
    case 'adaptive':
      return {
        fullWidthStyle: FullWidthEnum.Adaptive,
        class: '',
      }
    case 'fixed':
      return {
        class: tw`md:!w-[360px] w-full max-w-full`,
        fullWidthStyle: FullWidthEnum.Responsive,
      }
    case 'full-width':
      return { class: tw`!w-full `, fullWidthStyle: FullWidthEnum.Responsive }

    default: {
      return {
        fullWidthStyle: FullWidthEnum.Adaptive,
        class: '',
      }
    }
  }
})
</script>
