import type { RouteRecordRaw } from 'vue-router'

import {
  ABOUT_US,
  ABOUT_US_EXTERNAL_PAGE_VERIFIED_REFURBISHED,
} from './routes.constants'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: 'about-us',
      name: ABOUT_US,
      component: () => import('./AboutUs.vue'),
      meta: {
        disableClientSideNavigation: () => false,
      },
    },
    {
      name: ABOUT_US_EXTERNAL_PAGE_VERIFIED_REFURBISHED,
      path: '/:locale/quality',
      component: () =>
        import(
          '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
        ),
      meta: {
        disableClientSideNavigation: () => true,
      },
    },
  ]
}
