import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { merge } from '@backmarket/utils/object/merge'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import type { Location } from '@ds/types/Location'

/**
 * This composable is in charge of injecting the locale in the "param" property
 * if the given Location allows it.
 */
export function useRouteLocationWithLocale() {
  const i18n = useI18n()

  return (link: Location): Location => {
    if (typeof link === 'string') {
      return link
    }

    if ('type' in link && link.type === 'external') {
      return link
    }

    return merge({}, link, {
      params: {
        locale: toBcp47Locale(i18n.currentLocale),
      },
    })
  }
}
