import type { ContextData, UserContext } from '../types'

import { sendEvent } from '.'

export function setUserContext(userContext: UserContext) {
  const USER_CONTEXT_NOODLE_DEFINITION = {
    name: 'user-context',
    version: '0.0.1',
  }

  sendEvent(USER_CONTEXT_NOODLE_DEFINITION, {
    clientId: userContext.clientId as number,
    emailHashed: userContext.emailHashed,
    isStaff: userContext.isStaff,
    lastOrderDate: userContext.lastOrderDate,
    lifetimeRevenue: userContext.lifetimeRevenue as unknown as string,
    merchantId: userContext.merchantId as number,
    numberOfOrders: userContext.numberOfOrders,
    userId: userContext.userId,
  })
}

export function setContext(context: ContextData) {
  const CONTEXT_NOODLE_DEFINITION = {
    name: 'context',
    version: '0.0.1',
  }

  sendEvent(CONTEXT_NOODLE_DEFINITION, context)
}
