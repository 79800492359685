import { defineNuxtPlugin } from '#app'

import { useSessionId } from './composables/useSessionId'
import { useVisitorId } from './composables/useVisitorId'

function refreshCookiesExpiration() {
  if (process.server) {
    throw new Error(
      '[nuxt-module-identification] Cannot refresh cookies on server-side',
    )
  }

  useVisitorId({ forceRefresh: true })
  useSessionId({ forceRefresh: true })
}

export default defineNuxtPlugin((nuxtApp) => {
  // Refresh cookies expiration on every page change.
  nuxtApp.hook('app:mounted', refreshCookiesExpiration)
  nuxtApp.hook('page:start', refreshCookiesExpiration)
})
