<template>
  <div
    class="relative bg-cover bg-center"
    :class="styles.containerClass"
    :style="{
      backgroundImage: styles.backgroundImage,
    }"
  >
    <!-- overlay -->
    <div
      v-if="styles.hasOverlay"
      class="bg-surface-default-low absolute bottom-0 left-0 right-0 top-0 opacity-40"
      :class="[styles.overlayClass]"
    ></div>
    <div
      class="text-static-default-hi relative z-[2] flex flex-col items-center justify-center"
      :class="[styles.moodClass, containerClass]"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { BackgroundProps } from './Background.types'
import { getBackgroundStyles } from './Background.utils'

const props = defineProps<BackgroundProps & { containerClass?: string }>()

const styles = computed(() => getBackgroundStyles(props))
</script>
