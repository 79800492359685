/**
 * This function has been migrated from pastrami as is.
 * Its naming and behavior might be challenged after we identify
 * its necessity in the migration process.
 */
export function toBcp47Locale(locale: string) {
  const match = locale.match(
    /^(?<language>[a-zA-Z]{2}).(?<countryCode>[a-zA-Z]{2})$/,
  )

  return match
    ? `${match.groups?.language.toLowerCase()}-${match.groups?.countryCode.toLowerCase()}`
    : locale
}
