import { defineNuxtPlugin, useNuxtApp, useRouter } from '#imports'

// On first page load, vue-router does not differentiate the "from" and the "to" routes.
// The isInitialized flag helps keeping track of this first load.
let isInitialized = false

export default defineNuxtPlugin(() => {
  /* track a page view event when a navigation occurred */
  const router = useRouter()
  const { $tracking } = useNuxtApp()

  router.afterEach((to, from) => {
    if (from.path !== to.path || !isInitialized) {
      isInitialized = true
      $tracking.trackPageView({ from, to })
    }
  })
})
