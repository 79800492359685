import type { RouteRecordRaw } from 'vue-router'

export const PASTRAMI_CUSTOMER_CONVERSATION = {
  RESOLUTION: 'pastrami_customer_conversation_resolution',
  CAREFOLDER: 'pastrami_customer_conversation_carefolder',
}
const routes: readonly RouteRecordRaw[] = [
  {
    path: 'dashboard/customer-request/resolutions/:resolutionId',
    name: PASTRAMI_CUSTOMER_CONVERSATION.RESOLUTION,
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: {
      auth: {
        required: true,
      },
      disableClientSideNavigation: () => true,
    },
  },
  {
    path: 'dashboard/customer-request/:customerRequestId',
    name: PASTRAMI_CUSTOMER_CONVERSATION.CAREFOLDER,
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: {
      auth: {
        required: true,
      },
      disableClientSideNavigation: () => true,
    },
  },
]

export default routes
