import humps, { type OptionOrProcessor } from 'humps'

/**
 * Recursively transform object keys from camelCase to snake_case.
 *
 * This helper is here to provide better type safety around the underlying
 * methods from the `humps` package. We want to throw type errors when
 * this is called with class instances (like FormData, URLSearchParams).
 *
 * @example
 * decamelizeKeys({ helloWorld: 'hello' }) // { hello_world: 'hello' }
 */
export function decamelizeKeys<
  Type extends Record<string, unknown> | Array<Record<string, unknown>>,
>(object: Type, optionsOrProcessor?: OptionOrProcessor) {
  return humps.decamelizeKeys<Type>(object, optionsOrProcessor)
}
