import { defineNuxtRouteMiddleware, useRequestEvent } from '#imports'

/**
 * Override the active span resource name with the target route name.
 */
export default defineNuxtRouteMiddleware((to) => {
  const event = useRequestEvent()

  if (event && event.context.tracer) {
    const activeSpan = event.context.tracer.scope().active()

    if (activeSpan) {
      // We can use `Array#at` here because this code runs on the server.
      const route = to.matched.at(-1) ?? to
      const path = route.path.replace(/\([a-zA-Z|-]+\)/g, '')
      const resource = `${event.node.req.method} ${path}`

      activeSpan.setOperationName('web.request')
      activeSpan.setTag('resource.name', resource)
    }
  }
})
