import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { ROUTES } from './route-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REVIEW_COLLECTION = '' } =
    useRuntimeConfig().public

  const isReviewCollectionFrontAppsRouteOpenedInCountry =
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REVIEW_COLLECTION.includes(
      market.countryCode,
    )

  return [
    {
      name: ROUTES.FORM,
      path: 'review/:orderlineId',
      component: () => import('./review-collection/pages/ReviewCollection.vue'),
      meta: {
        disableClientSideNavigation: () =>
          !isReviewCollectionFrontAppsRouteOpenedInCountry,
      },
    },
    {
      name: ROUTES.PAGES.LANDING,
      path: 'r/l/:slugV2/:uuid',
      component: () => import('./reviews-display/pages/Reviews.vue'),
    },
    {
      name: ROUTES.PAGES.MERCHANT,
      path: 'r/s/:merchantName/:uuid',
      component: () => import('./reviews-display/pages/Reviews.vue'),
    },
    {
      name: ROUTES.PAGES.PRODUCT,
      path: 'r/p/:slugV2/:uuid',
      component: () => import('./reviews-display/pages/Reviews.vue'),
    },
    // Matching Pastrami route
    {
      name: ROUTES.PAGES.LEGACY_PRODUCT,
      path: 'r/p/:uuid',
      component: () =>
        import(
          '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
        ),
      meta: {
        disableClientSideNavigation: () => true,
      },
    },
    {
      name: ROUTES.PAGES.ALL,
      path: 'r',
      component: () => import('./reviews-display/pages/Reviews.vue'),
    },
  ]
}
