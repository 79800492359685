export const ERROR_TYPE = {
  feature: 'feature_error',
  fetch: 'fetch_error',
  uncategorized: 'uncategorized_error',
} as const

export const LOGGED_HTTP_STATUS_CODE = [500, 502, 503, 530]

export const SALES_CUSTOMER_CARE_SCOPE = {
  contactConfirmationPage: '[CONTACT CONFIRMATION PAGE]',
  diagnosis: '[DIAGNOSIS FLOW]',
  helpCenter: '[HELP CENTER]',
  myOrders: '[MY ORDERS]',
  myProfile: '[MY PROFILE]',
  resolutionFlow: '[RESOLUTION FLOW]',
  returnConfirmationPage: '[RETURN CONFIRMATION PAGE]',
  straightRefundConfirmationPage: '[STRAIGHT REFUND CONFIRMATION PAGE]',
} as const

export const SQUAD_NAME = '[SALES_CUSTOMER_CARE]'

export type SalesCustomerCareScope =
  (typeof SALES_CUSTOMER_CARE_SCOPE)[keyof typeof SALES_CUSTOMER_CARE_SCOPE]

export type ErrorType = (typeof ERROR_TYPE)[keyof typeof ERROR_TYPE]
