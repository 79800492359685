import { useRequestEvent } from '#imports'
import type { H3Event } from 'h3'

import { trace } from '../helpers/trace'

/**
 * Simple convenience wrapper around our own `trace` function. It's provided
 * to simplify the usage in Nuxt context, and works in both server and client
 * environments.
 *
 * @example
 * const options = { ... }
 * const data = useTrace('span.name', options, () => {
 *   return $httpFetch('/api/endpoint')
 * })
 */
export function useTrace<T>(
  name: Parameters<typeof trace<T>>[1],
  options: Parameters<typeof trace<T>>[2],
  callback: Parameters<typeof trace<T>>[3],
) {
  // We must enforce undefined as possible type because when `useRequestEvent`
  // is executed client-side, it returns undefined. However, Nuxt types do
  // not take this into consideration, and always return an H3Event type.
  // See: https://nuxt.com/docs/api/composables/use-request-event
  const event = useRequestEvent() as H3Event | undefined

  if (event) {
    return trace(event, name, options, callback)
  }

  return callback()
}
