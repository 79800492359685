export default {
  logout: {
    id: 'dashboard_menu_logout',
    defaultMessage: 'Logout',
  },
  myBuybackOffers: {
    id: 'dashboard_menu_buyback',
    defaultMessage: 'My buyback offers',
  },
  favorites: {
    id: 'dashboard_menu_my_favorites',
    defaultMessage: 'My favorites',
  },
  myOrders: {
    id: 'dashboard_menu_orders',
    defaultMessage: 'My orders',
  },
  myProfile: {
    id: 'dashboard_menu_info',
    defaultMessage: 'My profile',
  },
  referFriend: {
    id: 'dashboard_menu_refer_friend',
    defaultMessage: 'Refer a friend',
  },
  other: {
    id: 'dashboard_menu_other',
    defaultMessage: 'Other',
  },
}
