import { default as indexCV1teRXLqtMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/index.vue?macro=true";
export default [
  {
    name: indexCV1teRXLqtMeta?.name ?? "locale-sr-demopage",
    path: indexCV1teRXLqtMeta?.path ?? "/:locale()/sr-demopage",
    meta: indexCV1teRXLqtMeta || {},
    alias: indexCV1teRXLqtMeta?.alias || [],
    redirect: indexCV1teRXLqtMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/index.vue").then(m => m.default || m)
  }
]