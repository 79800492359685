import { createHttpEndpoint } from '../../utils'
import type { BuybackOrderMessage } from '../models'

/**
 * Post a buyback messages on discussion for a given order
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition#tag/Customer/operation/post-bm-buyback-customer-orders-messages}
 */
export const postCustomerOrderMessage = createHttpEndpoint<BuybackOrderMessage>(
  {
    method: 'POST',
    operationId: 'postCustomerOrderMessage',
    path: '/bm/buyback/v1/customer/orders/:id/messages',
  },
)
