import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api/src/api-models/Market'

/*
 * The repair tracking page name and URL are retained as this feature will be deprecated in the future.
 * We need to maintain it until a final deprecation date is determined.
 */

export const REPAIR = {
  TRACKING: 'repair_tracking',
}

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REPAIR_TRACKING } =
    useRuntimeConfig().public
  const isRepairTrackingFSMEnabled = (
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_REPAIR_TRACKING as string
  )?.includes(market.countryCode)

  return [
    {
      path: 'dashboard/repair/:repairId',
      name: REPAIR.TRACKING,
      component: () => import('./Noop.vue'),
      meta: {
        auth: {
          required: true,
        },
        disableClientSideNavigation: () => !isRepairTrackingFSMEnabled,
      },
    },
  ]
}
