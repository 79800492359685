import { useRuntimeConfig } from '#imports'

import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'

export const MAPPING_FRONT_APPS_LOCALE_TO_ZD_LOCALE: Record<string, string> = {
  'de-at': 'de-at',
  'de-de': 'de',
  'el-gr': 'el',
  'en-gb': 'en-gb',
  'en-au': 'en-au',
  'en-ie': 'en-ie',
  'en-us': 'en-us',
  'es-es': 'es',
  'fi-fi': 'fi',
  'fr-be': 'fr-be',
  'fr-fr': 'fr-fr',
  'it-it': 'it',
  'ja-jp': 'ja',
  'ko-kr': 'ko',
  'nl-nl': 'nl',
  'pt-pt': 'pt',
  'sv-se': 'sv',
  'sk-sk': 'sk',
}

/**
 * Composable to retrieve the zendesk faq base url with the correct locale and environment.
 *
 * @example
 * To retrieve the faqUrl param
 * ```
 * const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()
 * ```
 */

interface useZendeskFaqBaseUrlResponse {
  zendeskFaqBaseUrl: string
}

export function useZendeskFaqBaseUrl(): useZendeskFaqBaseUrlResponse {
  const { ZENDESK_BASE_URL } = useRuntimeConfig().public

  const currentLocale = useI18nLocale()

  const baseUrl = ZENDESK_BASE_URL
    ? (ZENDESK_BASE_URL as string)
    : 'https://help.backmarket.com/'

  const zendeskLocale =
    MAPPING_FRONT_APPS_LOCALE_TO_ZD_LOCALE[currentLocale] || 'en-us'

  return {
    zendeskFaqBaseUrl: baseUrl.concat(`hc/${zendeskLocale}`),
  }
}
